import { PROMOS_API_URL } from '../../buildConfig/processEnv';

export interface PromoSKUsType {
    pid_high_pids: {
        pid: string;
        high_pid: string;
    }[];
}

export interface PromoSKUsFormat {
    promoSKUsResponse: PromoSKUsType;
    promoSKUsError: number | undefined;
}

const PromoSKUs = async (
    token?: string,
    country?: string,
    promoID?: string,
): Promise<PromoSKUsFormat> => {
    let promoSKUsError: number | undefined;
    const url = new URL(`${PROMOS_API_URL}/promos/api/v2/promo-spec-high-pid/`);

    const postReq = async () => {
        const response = await fetch(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                country,
                promo_spec_id: promoID,
            }),
        });

        if (response.status >= 400) {
            promoSKUsError = response.status;
            return false;
        }

        const results = await response.json();
        return results;
    };

    const promoSKUsResponse = await postReq();
    return {
        promoSKUsResponse,
        promoSKUsError,
    };
};

export default PromoSKUs;
