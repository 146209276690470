import { Skeleton } from '@mantine/core';
import cx from 'classnames';

import type { ReactNode } from 'react';
import type { HeaderInfoSchema } from 'types/components/promos';

interface Props {
    view: string;
    region: string;
    checked?: boolean;
    info: HeaderInfoSchema;
}

interface TextProps {
    children: ReactNode;
}

const Label = ({ children }: TextProps) => (
    <span className="text-xxs uppercase text-primary-60">{children}</span>
);

const Value = ({ children }: TextProps) => (
    <div className="text-base text-primary-70 md:text-lg">{children}</div>
);

const Column = ({ children }: TextProps) => (
    <div className={cx('flex flex-col items-center px-2 md:items-start')}>{children}</div>
);

export default function HeaderInfo({ region, view, info, checked = false }: Props) {
    const { macoLoad = false, volume = '', active = '' } = info;

    return (
        <div
            className={cx(
                'flex items-start gap-5 justify-between px-2 pt-4',
                'md:gap-2 md:justify-around md:items-center md:p-0 lg:pr-6',
            )}
        >
            <Column>
                <Label>
                    Inc. {(checked && region === 'AR') || region === 'PH' ? 'Revenue' : 'MACO'}
                </Label>
                <Value>
                    {macoLoad ? (
                        <Skeleton className="h-7 w-24" />
                    ) : (
                        info[
                            (checked && region === 'AR') || region === 'PH' ? 'revenue' : 'maco'
                        ].replace('$', region === 'PH' ? '₱' : '$')
                    )}
                </Value>
            </Column>
            <Column>
                <Label>Inc. {region === 'PH' ? 'Weight' : 'Volume'}</Label>
                <Value>
                    {macoLoad ? (
                        <Skeleton className="h-7 w-20" />
                    ) : (
                        volume.replace('hL', region === 'PH' ? 'kg' : 'hL')
                    )}
                </Value>
            </Column>
            <Column>
                <Label>
                    {view !== 'All' && view !== 'Table' ? 'Discount Spend' : '# of Promos'}
                </Label>
                <Value>
                    {macoLoad ? (
                        <Skeleton className="h-7 w-16" />
                    ) : (
                        active.replace('$', region === 'PH' ? '₱' : '$')
                    )}
                </Value>
            </Column>
        </div>
    );
}
