import cx from 'classnames';
import { CommonProps } from './types';

interface GridProps extends CommonProps {
    maxCols?: number;
}

export default function ContentGrid({ children, maxCols = 3, className = '' }: GridProps) {
    return (
        <div
            className={cx(
                'grid auto-rows-fr grid-cols-1 md:grid-cols-2 gap-4',
                {
                    'lg:grid-cols-3': maxCols > 2,
                    'xl:grid-cols-4': maxCols > 3,
                },
                className,
            )}
        >
            {children}
        </div>
    );
}
