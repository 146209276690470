import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Globe, MagnifyingGlass, Scales } from 'phosphor-react';
import { Switch, Table, Tabs, Tooltip } from '@mantine/core';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useRouter } from 'next/router';
import Select from 'andromeda/selects/select';
import { NewTypeSelectData, NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import { Button, Card, Spinner, Text } from 'andromeda';
import { primaryColors } from '@/constants/colors';
import StyledTable from 'andromeda/styledTable';
import {
    CATEGORY_DATA,
    COLOR_MAP_COMPARE,
    COMPARE_OPTIONS,
    REGION_SELECTOR,
} from '../../../constants/siberia/promos';
import BarChart from '../../utils/charts/barChart';
import { GradientDot } from '../../utils/GradientDot';
import { formatNumber } from '../../../utils/numberFormatHelpers';
import { useAppContext } from '../../../contexts/appContext';
import AllPromosSpecData from '../../../services/siberia/allPromosSpecsView';
import promosCompare from '../../../services/siberia/promosCompare';
import { PromosCompareType } from '../../../types/components/promos';
import { detailHelper, getKeyByValue, lifecycleFunc } from '../../../utils/promosHelpers';
import MultiLevelSelect from '../../../andromeda/multiSelect/multiLevelSelect';

import DatePicker from '../../../andromeda/DatePicker';
import { monthlyDurations } from '../../../constants/pricingIntelligence';
import { dateSubtractionMonthly } from '../../../utils/dateHelpers';
import useRouterQuery from '../../../hooks/useRouterQuery';

interface CompareProps {
    region: string;
    setRegion: (reg: string) => void;
    filterOptions?: {
        newFilter: NewTypeSelectData;
        filterLoading: boolean;
    };
    checked: boolean;
    setChecked: (val: boolean) => void;
}

export const Compare = ({
    region = 'all',
    setRegion,
    filterOptions,
    checked,
    setChecked,
}: CompareProps) => {
    const router = useRouter();
    const { user } = useAppContext();
    const [value, setValue] = useState<NewTypeSelectValues>({});
    const [valueCompare, setValueCompare] = useState<NewTypeSelectValues>({});
    const [activeTab, setActiveTab] = useState<string | null>('groups');
    const [isExpanded, setIsExpanded] = useState('');
    const [promos, setPromos] = useState<[string, string]>(['', '']);
    const [pids, setPIDS] = useState<string[]>(['', '']);
    const [compareResults, setCompareResults] = useState<PromosCompareType[]>([]);
    const [activeResult, setActiveResult] = useState(activeTab);
    const [search, setSearch] = useState<string>('');
    const [searchCompare, setSearchCompare] = useState<string>('');
    const [minDate, setMinDate] = useState<string>(
        monthlyDurations[monthlyDurations.length - 1].value,
    );
    const [maxDate, setMaxDate] = useState<string>(dateSubtractionMonthly(0, 'd'));
    const [minDateCompare, setMinDateCompare] = useState<string>(
        monthlyDurations[monthlyDurations.length - 1].value,
    );
    const [maxDateCompare, setMaxDateCompare] = useState<string>(dateSubtractionMonthly(0, 'd'));
    const { handleURLParamsChange } = useRouterQuery();

    const { data, isLoading: tableLoading } = useQuery({
        queryKey: [
            'AllPromosSpecDataCompare',
            user?.siberiaToken,
            region,
            ['2022-08-31', '2023-08-31'],
            {},
            1,
        ],
        queryFn: () =>
            AllPromosSpecData(
                user?.siberiaToken,
                region,
                ['2022-08-31', '2023-08-31'],
                {},
                1,
                '',
                3000,
            ),
        enabled: !!user?.siberiaToken,
    });

    const handleDurationChange = useCallback((startDate: string, endDate?: string) => {
        setMinDate(startDate);
        if (endDate) setMaxDate(moment(endDate).endOf('month').format('YYYY-MM-DD'));
    }, []);

    const handleDurationChangeCompare = useCallback((startDate: string, endDate?: string) => {
        setMinDateCompare(startDate);
        if (endDate) setMaxDateCompare(moment(endDate).endOf('month').format('YYYY-MM-DD'));
    }, []);

    const groupedIndividualsFiltersDataOBJ = useMemo(
        () =>
            data?.allPromosSpecDataResponse?.promo_spec_view?.reduce(
                (acc: Record<string, string>, row) => {
                    if (moment(row?.['startdate']).diff(moment(), 'weeks') > 3) return [];

                    return {
                        ...acc,
                        [row.promo_spec_id]: [...(acc?.[row.promo_spec_id] ?? []), row.pid],
                    };
                },
                {},
            ) ?? {},
        [data?.allPromosSpecDataResponse?.promo_spec_view],
    );

    const groupedIndividualsFiltersData = useMemo(
        () =>
            Object.keys(groupedIndividualsFiltersDataOBJ)?.sort((a: string, b) =>
                a.localeCompare(b),
            ),
        [groupedIndividualsFiltersDataOBJ],
    );

    const [getCompareData, compareError, loading] = promosCompare(
        user?.siberiaToken,
        region,
        [
            [minDate, maxDate],
            [minDateCompare, maxDateCompare],
        ],
        activeTab !== 'individual' && [value, valueCompare],
        activeTab === 'individual' && [
            { promo_spec_id: promos[0], pid: pids[0] },
            { promo_spec_id: promos[1], pid: pids[1] },
        ],
    );

    const getIndResults = async () => {
        setActiveResult(activeTab);
        const compareData = await getCompareData();
        setCompareResults(compareData ?? []);
    };

    const filteredChartData = useMemo(() => {
        if (
            !compareResults ||
            !Array.isArray(compareResults) ||
            compareResults.length === 0 ||
            activeTab !== activeResult
        )
            return { chartData: [], tableData: [] };
        let loadingCondition = false;
        const chartData = compareResults?.map((row, i) => ({
            label: `Group ${i + 1}`,
            backgroundColor: COLOR_MAP_COMPARE[i],
            barPercentage: 1,
            categoryPercentage: 0.3,
            data: [
                {
                    y: row.maco_uplift,
                    x: 'MACO UPLIFT',
                },
                {
                    y: row.volume_uplift,
                    x: 'VOLUME UPLIFT',
                },
                {
                    y: row.coverage_uplift,
                    x: 'COVERAGE UPLIFT',
                },
            ],
        }));
        const tableData = compareResults?.flatMap((row, i) => {
            if (row?.group_name === 'not found') {
                loadingCondition = true;
                return [];
            }
            const specID = row.group_name.split('_');
            specID[0] = specID[0].replace(/0+$/, '').replaceAll('-', '');
            return activeTab === 'individual'
                ? {
                      promo_spec_id: specID.join('_'),
                      optimization: row?.['optimization'],
                      start_date: moment(row?.['start_date']).format('DD MMM Y'),
                      end_date: moment(row?.['end_date']).format('DD MMM Y'),
                      pocs: row?.['num_pocs'],
                      spend: row?.['spend_per_poc'].toFixed(4),
                  }
                : {
                      promo_spec_id: `Group ${i + 1}`,
                      optimization: row?.['num_promo_specs'],
                      start_date: row?.['num_pocs'],
                      end_date: row?.['spend_per_poc'].toFixed(4),
                  };
        });
        loadingCondition = false;

        return { chartData, tableData, loadingCondition };
    }, [activeResult, activeTab, compareResults]);

    const buttonConditions = [
        promos[0] !== '' && pids[0] !== '' && promos[1] !== '' && pids[1] !== '',
        Object.keys(value).length > 0 && Object.keys(valueCompare).length > 0,
    ];

    useEffect(() => {
        if (!router.isReady || !filterOptions?.newFilter?.Designer?.data) return;
        const {
            compare_promos_filter: comparePromosFilter,
            compare_promos_filter_v2: comparePromosFilterV2,
        } = router.query;
        const promosFilter =
            typeof comparePromosFilter === 'string' ? [comparePromosFilter] : comparePromosFilter;
        if (typeof promosFilter === 'object') {
            const filters = {};
            const dataPoints = {};
            [...CATEGORY_DATA, filterOptions?.newFilter].forEach(row => {
                Object.keys(row).forEach(val => {
                    dataPoints[val] = row[val].data;
                });
            });
            promosFilter.forEach(row => {
                const key = getKeyByValue(dataPoints, row);
                if (!key || !row) return;
                if (!filters[key]) {
                    filters[key] = { type: 'multiSelect', value: [row] };
                } else {
                    filters[key].value.push(row);
                }
            });
            setValue(filters);
        }
        const promosFilterCompare =
            typeof comparePromosFilterV2 === 'string'
                ? [comparePromosFilterV2]
                : comparePromosFilterV2;
        if (typeof promosFilterCompare === 'object') {
            const filters = {};
            const dataPoints = {};
            [...CATEGORY_DATA, filterOptions?.newFilter].forEach(row => {
                Object.keys(row).forEach(val => {
                    dataPoints[val] = row[val].data;
                });
            });
            promosFilterCompare.forEach(row => {
                const key = getKeyByValue(dataPoints, row);
                if (!key || !row) return;
                if (!filters[key]) {
                    filters[key] = { type: 'multiSelect', value: [row] };
                } else {
                    filters[key].value.push(row);
                }
            });
            setValueCompare(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady, router.query, filterOptions]);

    const noDataOrLoading =
        // !compareResults &&
        filteredChartData?.loadingCondition || filteredChartData.chartData.length === 0 || loading;

    return (
        <div>
            <div className="my-6 mb-8 flex flex-row justify-end gap-3 pl-36">
                {region === 'AR' && (
                    <Switch
                        className="cursor-pointer"
                        size="xl"
                        checked={checked}
                        onChange={event => setChecked(event.currentTarget.checked)}
                        onLabel="Revenue"
                        offLabel="MACO"
                        classNames={{ trackLabel: '!px-2 !pl-3' }}
                    />
                )}
                <Select
                    value={region}
                    data={REGION_SELECTOR}
                    onChange={r => {
                        setValue({});
                        setValueCompare({});
                        setRegion(r);
                    }}
                    width={135}
                    cleanDesign
                    leftSection={<Globe size={20} className="mb-0.5 text-primaryBlue" />}
                />
            </div>
            <div
                className="m-auto flex h-full flex-row gap-4 overflow-auto"
                style={{
                    maxHeight: 'calc(100vh - 210px)',
                }}
            >
                <Card title="Promo Selection" withDivider={false} className="min-w-[350px]">
                    <Tabs
                        defaultValue="groups"
                        classNames={{
                            tab: 'text-primary-90 hover:text-primary-60 !border-b-2 border-none border-transparent hover:bg-primary-5 data-[active=true]:!border-risd-blue',
                        }}
                        value={activeTab}
                        onChange={setActiveTab}
                    >
                        <Tabs.List grow>
                            <Tabs.Tab value="groups">GROUPS</Tabs.Tab>
                            <Tabs.Tab value="individual">INDIVIDUAL</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="individual" pt="xs">
                            <div className="flex flex-col">
                                <div className="flex flex-col gap-2">
                                    Promo 1
                                    <Select
                                        value={promos[0]}
                                        data={groupedIndividualsFiltersData ?? []}
                                        leftSection={
                                            <MagnifyingGlass
                                                size={16}
                                                className="!text-navy-solid-50"
                                            />
                                        }
                                        onChange={v => {
                                            setPIDS(prev => ['', prev[1]]);
                                            setPromos(prev => [v, prev[1]]);
                                        }}
                                        disabled={tableLoading}
                                        placeholder="Enter promo spec ID"
                                        searchVal={search}
                                        onSearchChange={setSearch}
                                        gradientBG
                                        width={280}
                                    />
                                    <Select
                                        value={pids[0]}
                                        data={groupedIndividualsFiltersDataOBJ?.[promos[0]] || []}
                                        onChange={v => setPIDS(prev => [v, prev[1]])}
                                        disabled={tableLoading}
                                        placeholder="Select SKU"
                                        gradientBG
                                        width={280}
                                    />
                                </div>
                                <div className="mt-4 flex flex-col gap-2 border-t border-border-color pt-2">
                                    Promo 2
                                    <Select
                                        value={promos[1]}
                                        data={groupedIndividualsFiltersData ?? []}
                                        leftSection={
                                            <MagnifyingGlass
                                                size={16}
                                                className="!text-navy-solid-50"
                                            />
                                        }
                                        onChange={v => {
                                            setPIDS(prev => [prev[0], '']);
                                            setPromos(prev => [prev[0], v]);
                                        }}
                                        disabled={loading}
                                        placeholder="Enter promo spec ID"
                                        searchVal={searchCompare}
                                        onSearchChange={setSearchCompare}
                                        gradientBG
                                        width={280}
                                    />
                                    <Select
                                        value={pids[1]}
                                        data={groupedIndividualsFiltersDataOBJ?.[promos[1]] || []}
                                        onChange={v => setPIDS(prev => [prev[0], v])}
                                        disabled={loading}
                                        placeholder="Select SKU"
                                        gradientBG
                                        width={280}
                                    />
                                </div>
                                <Button
                                    leftSection={<Scales size={16} className="!text-white" />}
                                    className="mt-12"
                                    onClick={getIndResults}
                                    disabled={tableLoading || !buttonConditions[0] || loading}
                                    inactive={tableLoading || !buttonConditions[0] || loading}
                                >
                                    Compare
                                </Button>
                            </div>
                        </Tabs.Panel>
                        <Tabs.Panel value="groups" pt="xs">
                            <div className="flex flex-col">
                                <div className="flex flex-col gap-2">
                                    Promo Group 1
                                    <div className="w-full">
                                        <MultiLevelSelect
                                            data={[
                                                ...(region === 'PH' ? [] : CATEGORY_DATA),
                                                filterOptions?.newFilter,
                                            ]}
                                            values={value}
                                            onChange={v => {
                                                setValue(v);
                                                const params = Object.values(v)
                                                    .flatMap(row =>
                                                        typeof row.value === 'object' &&
                                                        row.value.length > 0
                                                            ? (row.value as any)
                                                            : [],
                                                    )
                                                    .flat(1);
                                                handleURLParamsChange({
                                                    key: 'compare_promos_filter',
                                                    value: params,
                                                });
                                            }}
                                            loading={filterOptions?.filterLoading}
                                        />
                                    </div>
                                    <DatePicker
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        handleChange={(s, e) => {
                                            handleDurationChange(s, e);
                                        }}
                                        id="promos-compare-duration"
                                        isMonthSelector
                                    />
                                </div>
                                <div className="my-2 mb-4 border-t border-border-color" />
                                <div className="flex flex-col gap-2">
                                    Promo Group 2
                                    <div className="w-full">
                                        <MultiLevelSelect
                                            data={[
                                                ...(region === 'PH' ? [] : CATEGORY_DATA),
                                                filterOptions?.newFilter,
                                            ]}
                                            values={valueCompare}
                                            onChange={v => {
                                                setValueCompare(v);
                                                const params = Object.values(v)
                                                    .flatMap(row =>
                                                        typeof row.value === 'object' &&
                                                        row.value.length > 0
                                                            ? (row.value as any)
                                                            : [],
                                                    )
                                                    .flat(1);
                                                handleURLParamsChange({
                                                    key: 'compare_promos_filter_v2',
                                                    value: params,
                                                });
                                            }}
                                            loading={filterOptions?.filterLoading}
                                            secondary
                                        />
                                    </div>
                                    <DatePicker
                                        minDate={minDateCompare}
                                        maxDate={maxDateCompare}
                                        handleChange={(s, e) => {
                                            handleDurationChangeCompare(s, e);
                                        }}
                                        id="promos-compare-duration-2"
                                        isMonthSelector
                                    />
                                </div>
                                <Button
                                    leftSection={<Scales size={16} className="!text-white" />}
                                    className="mt-12"
                                    loading={false}
                                    onClick={getIndResults}
                                    disabled={!buttonConditions[1]}
                                    inactive={!buttonConditions[1]}
                                >
                                    Compare
                                </Button>
                            </div>
                        </Tabs.Panel>
                    </Tabs>
                </Card>
                {noDataOrLoading ? (
                    <Card
                        withDivider={false}
                        innerClassName="w-full min-h-full grow"
                        className="w-full"
                    >
                        <div
                            className="!my-auto flex w-full flex-col items-center justify-center gap-2 text-center"
                            style={{
                                height: 'calc(100vh - 300px)',
                            }}
                        >
                            {filteredChartData?.loadingCondition || loading ? (
                                <Spinner size={52} className="!h-fit" />
                            ) : (
                                filteredChartData?.chartData.length === 0 && (
                                    <Scales size={52} className="!text-navy-solid-30" />
                                )
                            )}
                            <Text type="22Reg" className="!text-navy-solid-30">
                                {filteredChartData?.loadingCondition || loading
                                    ? 'Awaiting Data for Selected Promos'
                                    : filteredChartData?.chartData.length === 0 &&
                                      'No Active Comparisons'}
                            </Text>
                            <Text type="18Reg" className="pt-2 !text-navy-solid-30">
                                {!(filteredChartData?.loadingCondition || loading) &&
                                    filteredChartData?.chartData.length === 0 &&
                                    'Select individual promos or groups of promos to begin comparing.'}
                            </Text>
                        </div>
                    </Card>
                ) : (
                    <div
                        className="grid w-full grid-rows-2 gap-4"
                        style={{
                            minHeight: 'calc(100vh - 210px)',
                        }}
                    >
                        <Card title="Comparison" loading={loading} className="size-full">
                            <BarChart
                                data={{
                                    datasets: filteredChartData?.chartData,
                                }}
                                legend={{ display: false }}
                                tooltip={{
                                    callbacks: {
                                        label: context => formatNumber(context?.raw?.['y'], '0.0%'),
                                    },
                                }}
                                yAxis={{
                                    ticks: {
                                        callback(tickValue) {
                                            return formatNumber(tickValue, '0.0%');
                                        },
                                    },
                                }}
                            />
                        </Card>
                        {!noDataOrLoading && (
                            <StyledTable
                                loading={loading}
                                headers={
                                    <Table.Tr className="!bg-transparent">
                                        <Table.Th>
                                            {activeTab === 'individual'
                                                ? 'Promo Spec ID'
                                                : 'Promo Group'}
                                        </Table.Th>
                                        {activeTab === 'individual' ? (
                                            <>
                                                <Table.Th className="!text-center">
                                                    Optimization
                                                </Table.Th>
                                                <Table.Th className="!text-center">
                                                    Start Date
                                                </Table.Th>
                                                <Table.Th className="!text-center">
                                                    End Date
                                                </Table.Th>
                                            </>
                                        ) : (
                                            <Table.Th className="!text-center">
                                                # of Promos
                                            </Table.Th>
                                        )}
                                        <Table.Th className="!text-center"># of POCs</Table.Th>
                                        <Table.Th className="!text-center">
                                            Discount Spend per POC
                                        </Table.Th>
                                    </Table.Tr>
                                }
                                body={filteredChartData?.tableData.map((row, i) => (
                                    <>
                                        <Table.Tr
                                            key={row.promo_spec_id}
                                            className="cursor-pointer items-center rounded-2xl text-primary hover:bg-risd-blue-10"
                                            onClick={() => {
                                                if (activeTab !== 'groups')
                                                    setIsExpanded(prev =>
                                                        prev === `${row.promo_spec_id}${i}`
                                                            ? ''
                                                            : `${row.promo_spec_id}${i}`,
                                                    );
                                            }}
                                        >
                                            <Table.Td>
                                                <Tooltip label={row.promo_spec_id}>
                                                    <div className="flex items-center gap-2 overflow-hidden py-2 pt-4">
                                                        <GradientDot color={COLOR_MAP_COMPARE[i]} />
                                                        {String(row.promo_spec_id).split('_')[0]}
                                                    </div>
                                                </Tooltip>
                                            </Table.Td>
                                            <Table.Td className="py-2 pt-4">
                                                {String(row.optimization).replace(
                                                    'tiered-discount',
                                                    'Stepped',
                                                )}
                                            </Table.Td>
                                            <Table.Td className="py-2 pt-4">
                                                {row.start_date}
                                            </Table.Td>
                                            <Table.Td className="py-2 pt-4">
                                                {row.end_date}
                                            </Table.Td>
                                            <Table.Td className="py-2 pt-4">{row.pocs}</Table.Td>
                                            {activeTab === 'individual' && (
                                                <Table.Td className="py-2 pt-4">
                                                    {row.spend}
                                                </Table.Td>
                                            )}
                                        </Table.Tr>
                                        <div
                                            className={classNames(
                                                'flex flex-row justify-between mb-1 transition-all duration-600 opacity-100 pointer-events-none border-y py-2 pt-1 border-border-color !w-[55vw]',
                                                {
                                                    '!opacity-0 !h-0 !pb-0 !mb-0 !w-0 hidden max-h-0 invisible':
                                                        isExpanded !==
                                                        `${filteredChartData?.tableData?.[0]?.promo_spec_id}${0}`,
                                                },
                                            )}
                                        >
                                            {COMPARE_OPTIONS.map(option => (
                                                <div
                                                    key={option.value}
                                                    className="flex flex-col text-sm"
                                                >
                                                    <div className="text-navy-solid-15">
                                                        {option.title}
                                                    </div>
                                                    {option.value === 'sku' ? (
                                                        pids[0]
                                                    ) : (
                                                        <div>
                                                            {option.value === 'apply_lifecycle'
                                                                ? lifecycleFunc(
                                                                      compareResults?.[0]?.[
                                                                          option.value
                                                                      ],
                                                                  )
                                                                : detailHelper(
                                                                      String(
                                                                          compareResults?.[0]?.[
                                                                              option.value || 'N/A'
                                                                          ],
                                                                      )
                                                                          .replace('PROMOS_', '')
                                                                          .replace('_', ' '),
                                                                      option.title,
                                                                      filteredChartData
                                                                          ?.tableData?.[0]
                                                                          ?.optimization,
                                                                  )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ))}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
