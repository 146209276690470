import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { CATEGORY_LABELS, CATEGORY_LABELS_PH } from 'constants/siberia/promos';
import { dateSubtractionMonthly } from 'utils/dateHelpers';
import {
    HeaderNavItem,
    HeaderNavLink,
    HeaderNavList,
} from '@/components/responsive/ContentHeaderNav';
import { monthlyDurations } from 'constants/pricingIntelligence';
import { useAppContext } from 'contexts/appContext';
import { useUserAccess } from 'hooks/useUserAccess';
import AllPromosSpecsData from 'services/siberia/allPromosSpecsData';
import PromosFilterOptions from 'services/siberia/promosFilterOptions';
import { headerHelper } from '@/utils/promosHelpers';
import type { HeaderInfoSchema } from 'types/components/promos';
import StyledTab from 'andromeda/tab/tabComponent';
import useRouterQuery from '@/hooks/useRouterQuery';
import cx from 'classnames';
import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import IndividualPromo from './individualPromo';
import HeaderInfo from './HeaderInfo';
import AllPromos from './allPromos';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';
import { Compare } from './tabs/compare';

// TODO not sure why I all of a sudden need this, but it wasn't needed before
// import 'chart.js/auto';

export default function PromosMain() {
    const {
        router: { query, isReady },
        handleURLParamsChange,
    } = useRouterQuery();
    const [view, setView] = useState<string>('All');
    const [tab, setTab] = useState<string>('overview');

    const { user } = useAppContext();
    const isNestleUser = useUserAccess({ userGroupWhitelist: ['spaceport-PH-NESTLE-access'] });

    const [region, setRegion] = useState<string>(isNestleUser ? 'PH' : 'BR');
    const [sku, setSku] = useState('9320');
    const [filters, setFilters] = useState<NewTypeSelectValues>({});
    const [minDate, setMinDate] = useState<string>(monthlyDurations[3].value);
    const [maxDate, setMaxDate] = useState<string>(dateSubtractionMonthly(0, 'd'));
    const [checked, setChecked] = useState(false);
    const [count, setCount] = useState(0);
    const [headerInfo, setHeaderInfo] = useState<HeaderInfoSchema>({
        maco: '$20,183,582',
        volume: '792,298 hL',
        active: '$2,183,582',
        revenue: '$20,183,582',
        macoLoad: true,
    });

    useEffect(() => {
        if (!isReady) return;
        const { tab: queryTab, view: queryView } = query;
        if (typeof queryView === 'string') setView(queryView);
        if (typeof queryTab === 'string') setTab(queryTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady]);

    const handleDurationChange = useCallback((startDate: string, endDate?: string) => {
        setMinDate(startDate);
        if (endDate) {
            setMaxDate(moment(endDate).endOf('month').format('YYYY-MM-DD'));
        }
    }, []);

    const { data, isLoading } = useQuery({
        queryKey: [
            'AllPromosSpecDataRegion',
            user?.siberiaToken,
            region,
            [minDate, maxDate],
            filters,
        ],
        queryFn: () =>
            AllPromosSpecsData(
                user?.siberiaToken,
                region,
                [minDate, region === 'PH' ? '2024-10-32' : maxDate],
                filters,
            ),
        enabled: view === 'All' || view === 'Table',
    });

    useEffect(() => {
        if (view === 'All' || view === 'Table')
            setHeaderInfo({
                active: headerHelper(data?.allPromosSpecDataResponse?.active_promo_specs, '$0,0'),
                maco: headerHelper(data?.allPromosSpecDataResponse?.incremental_maco_total, '$0,0'),
                revenue: headerHelper(data?.allPromosSpecDataResponse?.revenue_volume_total, '0,0'),
                volume: headerHelper(
                    data?.allPromosSpecDataResponse?.incremental_volume_total,
                    '$0,0',
                ),
                macoLoad: isLoading,
            });
    }, [
        data,
        data?.allPromosSpecDataResponse?.active_promo_specs,
        data?.allPromosSpecDataResponse?.incremental_maco_total,
        data?.allPromosSpecDataResponse?.incremental_volume_total,
        data?.allPromosSpecDataResponse?.revenue_volume_total,
        isLoading,
        view,
    ]);

    const { data: filterData, isLoading: filterLoading } = useQuery({
        queryKey: ['PromosFilterOptions', user?.siberiaToken, region],
        queryFn: () => PromosFilterOptions(user?.siberiaToken, region),
    });

    const filterOptions = useMemo(() => {
        const newFilter = {};
        if (filterLoading || !filterData) {
            return { newFilter: {}, filterLoading: true };
        }
        Object.entries(filterData?.promosFilterOptionsResponse)?.forEach(row => {
            const rowName = (region === 'PH' ? CATEGORY_LABELS_PH : CATEGORY_LABELS)[row[0]];
            if (rowName) {
                newFilter[rowName] = {
                    type: 'multiSelect',
                    data:
                        rowName === 'Region'
                            ? row[1].sort().filter(v => v !== 'no_allocations')
                            : row[1].sort(),
                };
            }
        });
        return { newFilter, filterLoading };
    }, [filterData, filterLoading, region]);

    return (
        <StyledTab.Wrapper
            onChange={e => {
                setTab(e);
                setView({ overview: 'All', search: 'Table' }[e]);
                handleURLParamsChange({
                    multipleValues: {
                        tab: e,
                        view: { overview: 'All', search: 'Table' }[e],
                    },
                });
            }}
            value={tab}
            values={[
                {
                    component: (
                        <AllPromos
                            checked={checked}
                            countryData={data}
                            countryLoading={isLoading}
                            date={[minDate, maxDate]}
                            filterOptions={filterOptions}
                            region={region}
                            setChecked={setChecked}
                            setCount={setCount}
                            setDate={handleDurationChange}
                            setRegion={setRegion}
                            setSku={setSku}
                            setValue={setFilters}
                            setView={setView}
                            value={filters}
                            view={view}
                        />
                    ),
                    value: 'overview',
                },
                {
                    value: 'search',
                    component:
                        view === 'All' || view === 'Table' ? (
                            <AllPromos
                                region={region}
                                setRegion={setRegion}
                                view={view}
                                setView={setView}
                                countryData={data}
                                countryLoading={isLoading}
                                setSku={setSku}
                                date={[minDate, maxDate]}
                                setDate={handleDurationChange}
                                value={filters}
                                setValue={setFilters}
                                filterOptions={filterOptions}
                                checked={checked}
                                setChecked={setChecked}
                                setCount={setCount}
                            />
                        ) : (
                            <IndividualPromo
                                setHeaderInfo={setHeaderInfo}
                                sku={sku}
                                setSku={setSku}
                                view={view}
                                setView={setView}
                                region={region}
                                checked={checked}
                                setChecked={setChecked}
                                count={count}
                            />
                        ),
                },
                {
                    value: 'compare',
                    component: (
                        <Compare
                            region={region}
                            setRegion={setRegion}
                            filterOptions={filterOptions}
                            checked={checked}
                            setChecked={setChecked}
                        />
                    ),
                },
            ]}
        >
            <SpaceportCommonHeader title="" className="!pb-0" containerClassName="!mb-0">
                <div
                    className={cx(
                        'flex flex-col-reverse pb-1 items-center justify-between gap-2',
                        'md:flex-row md:gap-5',
                    )}
                >
                    <StyledTab.Nav />
                    {tab !== 'compare' && (
                        <HeaderInfo
                            view={view}
                            region={region}
                            info={headerInfo}
                            checked={checked}
                        />
                    )}
                </div>
            </SpaceportCommonHeader>
            <div className="my-5">
                <StyledTab.Main />
            </div>
        </StyledTab.Wrapper>
    );
}
