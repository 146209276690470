import React from 'react';
import classNames from 'classnames';
import {
    LegendProps,
    MapHeatLegendProps,
    MapLayerTitleType,
    MapLegendProps,
} from '../types/mapWrapper';

import { LegendIcon } from './legendIcon';

const LegendDiv = ({ children, className, legend }: LegendProps) => (
    <div className={classNames(`bg-contrast-90 rounded-reg w-[210px] p-4 pb-2`, className)}>
        {legend && (
            <div className="mb-2 font-manrope text-[10px] font-semibold uppercase text-primary-50">
                {legend}
            </div>
        )}
        {children}
    </div>
);

export const Legend = ({
    typesToHide,
    setTypesToHide,
    list,
    typeColors,
    stackedDots,
    className,
    extraChildren,
    topContent,
    legend,
    itemClass,
}: MapLegendProps) => {
    const toggleType = (typeLabel: MapLayerTitleType) => {
        if (setTypesToHide) {
            const index = typesToHide ? typesToHide.indexOf(typeLabel) : -1;
            if (index > -1) {
                const newArr: MapLayerTitleType[] = [...(typesToHide || [])];
                newArr.splice(index, 1);
                setTypesToHide(newArr);
            } else {
                setTypesToHide([...(typesToHide || []), typeLabel]);
            }
        }
    };
    return (
        <LegendDiv legend={legend} className={className}>
            {topContent}
            {list.map(item => (
                <div
                    id={`consumers-opportunities-map-legend-icon-${item}`}
                    className="mb-3 flex cursor-pointer items-center gap-2"
                    onClick={() => toggleType(item)}
                    key={item}
                    aria-hidden="true"
                >
                    <div>
                        <LegendIcon
                            color={typeColors[item]}
                            disabled={typesToHide && typesToHide?.indexOf(item) > -1}
                            stackedDots={stackedDots}
                        />
                    </div>
                    <h5 className={`m-0 uppercase text-primary ${itemClass} text-sm !text-white`}>
                        {item}
                    </h5>
                </div>
            ))}
            {extraChildren}
        </LegendDiv>
    );
};

export const MapHeatLegend = ({
    legend,
    colors = ['#EBB938', '#F11B97'],
    className,
}: MapHeatLegendProps) => (
    <div
        className={classNames(
            'flex flex-col w-[169px] h-[88px] p-4 pb-2 shadow bg-secondary  backdrop-filter rounded-reg',
            className,
        )}
    >
        {legend && (
            <div className="font-manrope text-[10px] font-semibold uppercase text-primary-50">
                {legend}
            </div>
        )}
        <div className="my-2 flex flex-row justify-between font-manrope text-[10px] font-semibold text-primary-50">
            <div>LOWER</div>
            <div>HIGHER</div>
        </div>
        <div
            className="flex h-2 min-h-2 w-[137px] place-self-center rounded-sm"
            style={{
                background: `linear-gradient(90deg,${colors.reduce(
                    (acc, color, i) =>
                        `${acc} ${color} ${(i / (colors.length - 1)) * 100}%${
                            i === colors.length - 1 ? '' : ','
                        }`,
                    '',
                )})`,
            }}
        >
            &nbsp;
        </div>
    </div>
);
