import { Drawer, Skeleton, Switch, Table, Tabs, Tooltip } from '@mantine/core';
import { ArrowLeft, Lock } from 'phosphor-react';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Select from 'andromeda/selects/select';
import StyledTable from 'andromeda/styledTable';
import type { IndividualPromoProps } from '../../types/components/promos';
import { Overview } from './tabs/overview';
import { POC } from './tabs/poc';
import { EngagementFunnel } from './tabs/funnel';
import { OPTIONS, PH_OPTIONS } from '../../constants/siberia/promos';
import PromoSpecData from '../../services/siberia/promoSpecData';
import { useAppContext } from '../../contexts/appContext';
import { detailHelper, formatVal } from '../../utils/promosHelpers';
import PromoSKUs from '../../services/siberia/promoSKUs';

const tooltipEl = (
    <div className="flex flex-row items-center gap-2">
        <Lock size={16} />
        Coming soon!
    </div>
);
const valueHelper = (str: string) =>
    _.isUndefined(str) || str.toLowerCase() === 'undefined'
        ? 'N/A'
        : str.replace('PROMOS_', '').replace('_', ' ');

const IndividualPromo = ({
    setHeaderInfo,
    view,
    sku,
    setView,
    region,
    checked,
    setChecked,
    count,
}: IndividualPromoProps) => {
    const router = useRouter();
    const { user } = useAppContext();
    const [selectedSKU, setSelectedSKU] = useState(sku);
    const [activeTab, setActiveTab] = useState<string | null>('overview');
    const [selectedPID, setSelectedPID] = useState(undefined);
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        if (!router.isReady) {
            return;
        }
        const { selectedSKU: skuQuery, selectedPID: pidQuery, indTab } = router.query;
        if (typeof skuQuery === 'string') {
            setSelectedSKU(skuQuery);
        }
        if (typeof pidQuery === 'string') {
            setSelectedPID(pidQuery);
        }
        if (typeof indTab === 'string') {
            setActiveTab(indTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const { data, isLoading: loading } = useQuery({
        queryKey: ['PromoSpecData', user?.siberiaToken, view, selectedSKU, region],
        queryFn: () => PromoSpecData(user?.siberiaToken, view, selectedSKU, region),
        enabled: view !== 'All' && view !== 'Table',
    });

    const { data: promoSKUs, isLoading: skuLoading } = useQuery({
        queryKey: ['PromoSKUsIndividual', user?.siberiaToken, region, view],
        queryFn: () => PromoSKUs(user?.siberiaToken, region, view),
        enabled: view !== 'All' && view !== 'Table',
    });

    const skuTable = useMemo(
        () =>
            promoSKUs?.promoSKUsResponse?.pid_high_pids?.map((row, i) => (
                <Table.Tr
                    key={`row-${String(i)}`}
                    className={classNames('text-left border-collapse')}
                >
                    <Table.Td className="text-left capitalize">{row?.pid}</Table.Td>
                    <Table.Td className="text-left capitalize">{row?.high_pid}</Table.Td>
                </Table.Tr>
            )),
        [promoSKUs],
    );
    const skuSelection = (selected: string) => {
        const filtered = selected.split(' ');
        setSelectedSKU(filtered[0]);
        setSelectedPID(selected);
        router.replace({
            pathname: router.pathname,
            query: {
                ...router.query,
                selectedPID: selected,
                selectedSKU: filtered[0],
            },
        });
    };

    const filteredSKU = useMemo(
        () => ({
            skus: data?.promoSpecData?.['skus']?.map(row => `${row.pid} ${row.high_pid}`)?.sort(),
            defaultSKU: data?.promoSpecData?.['skus']?.find(
                row => row.pid === data?.promoSpecData?.['selected_pid'],
            ),
        }),
        [data],
    );

    return (
        <div className="flex flex-col">
            <Tabs
                defaultValue="overview"
                classNames={{
                    list: 'flex border-0 border-border-color',
                    tab: 'mx-4 !border-none !text-primary hover:!text-primary-60 active:!text-risd-blue hover:!bg-transparent py-[10px]',
                }}
                value={activeTab}
                onChange={v => {
                    setActiveTab(v);
                    router.replace({
                        pathname: router.pathname,
                        query: {
                            ...router.query,
                            indTab: v,
                        },
                    });
                }}
            >
                {activeTab === 'overview' && (
                    <div className="my-2 flex flex-row items-center gap-1">
                        <button
                            type="button"
                            className="flex h-9 cursor-pointer items-center gap-1 rounded p-1.5 hover:bg-primary-10"
                            onClick={() => {
                                setView('Table');
                                router.replace({
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        view: 'Table',
                                    },
                                });
                            }}
                        >
                            <ArrowLeft />
                            {view.replace(/0+$/, '').replaceAll('-', '')}
                        </button>
                        {activeTab === 'overview' &&
                            region !== 'PH' &&
                            (region === 'AR' ? (
                                <Switch
                                    className="ml-auto mr-6 cursor-pointer"
                                    size="xl"
                                    checked={checked}
                                    onChange={event => setChecked(event.currentTarget.checked)}
                                    onLabel="Revenue"
                                    offLabel="MACO"
                                />
                            ) : (
                                <div className="flex flex-row items-center">
                                    {loading ? (
                                        <>
                                            {'>'} <Skeleton className="h-12 w-[450px]" />
                                        </>
                                    ) : (
                                        filteredSKU?.skus &&
                                        filteredSKU.skus.length > 1 && (
                                            <>
                                                {'>'}
                                                <Select
                                                    value={
                                                        selectedPID ||
                                                        `${filteredSKU?.defaultSKU?.pid} ${filteredSKU?.defaultSKU?.high_pid}`
                                                    }
                                                    data={filteredSKU?.skus || []}
                                                    onChange={skuSelection}
                                                    className="w-[450px] bg-transparent pr-1.5 "
                                                    disabled={filteredSKU?.skus?.length < 1}
                                                    classNames={{
                                                        input: {
                                                            input: '!bg-transparent !border-transparent whitespace-nowrap !text-ellipsis max-w-full !overflow-hidden',
                                                        },
                                                    }}
                                                />
                                            </>
                                        )
                                    )}
                                </div>
                            ))}
                        <div className="ml-4 flex flex-row items-center capitalize">
                            {region === 'AR' ? (
                                <div
                                    className="flex cursor-pointer flex-row items-center text-[#466CF1] underline"
                                    onClick={() => setOpened(true)}
                                    onKeyDown={e => {
                                        setOpened(true);
                                    }}
                                    aria-hidden="true"
                                >
                                    SKU Count {count} - Bundle Details
                                </div>
                            ) : (
                                <div className="flex w-full flex-col">
                                    {region === 'PH' ? (
                                        <div className="flex flex-row gap-6">
                                            <div>
                                                SKU to Purchase{' '}
                                                {
                                                    promoSKUs?.promoSKUsResponse?.pid_high_pids?.[
                                                        promoSKUs?.promoSKUsResponse?.pid_high_pids
                                                            ?.length > 1
                                                            ? 1
                                                            : 0
                                                    ]?.pid
                                                }
                                            </div>
                                            <div>
                                                SKU to Push{' '}
                                                {
                                                    promoSKUs?.promoSKUsResponse?.pid_high_pids?.[0]
                                                        ?.pid
                                                }
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Tabs.List className="flex flex-row justify-between">
                    <div className="mb-2 flex w-full flex-row border-y border-gray-600 pb-1">
                        <Tabs.Tab
                            value="overview"
                            className={classNames('text-base', {
                                '!text-risd-blue': activeTab === 'overview',
                            })}
                        >
                            Promo Overview
                        </Tabs.Tab>
                        <Tabs.Tab
                            value="algo"
                            disabled
                            className={classNames('text-base', {
                                '!text-risd-blue': activeTab === 'algo',
                            })}
                        >
                            <Tooltip label={tooltipEl} position="bottom" withArrow withinPortal>
                                <div>Algo Decisions</div>
                            </Tooltip>
                        </Tabs.Tab>
                        <Tabs.Tab
                            value="poc"
                            disabled={region === 'PH'}
                            className={classNames('text-base', {
                                '!text-risd-blue': activeTab === 'poc',
                            })}
                        >
                            <Tooltip
                                label={tooltipEl}
                                position="bottom"
                                withArrow
                                withinPortal
                                disabled={region !== 'PH'}
                            >
                                <div>POC View</div>
                            </Tooltip>
                        </Tabs.Tab>
                        <Tabs.Tab
                            value="funnel"
                            disabled={region === 'PH'}
                            className={classNames('text-base', {
                                '!text-risd-blue': activeTab === 'funnel',
                            })}
                        >
                            <Tooltip
                                label={tooltipEl}
                                position="bottom"
                                withArrow
                                withinPortal
                                disabled={region !== 'PH'}
                            >
                                <div>Engagement Funnel</div>
                            </Tooltip>
                        </Tabs.Tab>
                        <Tabs.Tab
                            value="status"
                            disabled
                            className={classNames('text-base', {
                                '!text-risd-blue': activeTab === 'status',
                            })}
                        >
                            <Tooltip label={tooltipEl} position="bottom" withArrow withinPortal>
                                <div>Status</div>
                            </Tooltip>
                        </Tabs.Tab>
                    </div>
                    {activeTab === 'overview' && (
                        <div className="flex w-full max-w-full flex-row  justify-between overflow-auto pb-4">
                            {(region === 'PH' ? PH_OPTIONS : OPTIONS).map(row => (
                                <div key={row.value} className="flex flex-col text-sm">
                                    <div className="whitespace-nowrap text-gray-500">
                                        {row.title}
                                    </div>{' '}
                                    {loading ? (
                                        <Skeleton className="h-6 w-12" />
                                    ) : (
                                        <div>
                                            {row.title === 'DISC. RANGE' &&
                                            data?.promoSpecData?.['quantity_range'] === '0.0-0.0'
                                                ? 'Stepped'
                                                : valueHelper(
                                                      detailHelper(
                                                          String(
                                                              String(
                                                                  data?.promoSpecData?.[row.value],
                                                              ).toUpperCase(),
                                                          ),
                                                          row.title,
                                                          data?.promoSpecData?.['optimization'],
                                                      ),
                                                  )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Button to the compare page todo
                    <div className="flex flex-row items-center">
                        <Button
                            variant="gradient"
                            className="bg-gray-800"
                            onClick={() => {
                                setActiveTab('compare');
                            }}
                        >
                            Compare
                        </Button>
                    </div>
                    */}
                </Tabs.List>
                <Tabs.Panel value="overview">
                    <Overview
                        view={view}
                        setHeaderInfo={setHeaderInfo}
                        sku={sku}
                        data={data}
                        loading={loading}
                        setSelectedSKU={setSelectedSKU}
                        setSelectedPID={setSelectedPID}
                        region={region}
                        checked={checked}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="algo" pt="xs">
                    Algo Decisions
                </Tabs.Panel>
                <Tabs.Panel value="poc" pt="xs">
                    <POC
                        view={view}
                        setHeaderInfo={setHeaderInfo}
                        sku={sku}
                        region={region}
                        checked={checked}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="funnel" pt="xs">
                    <EngagementFunnel
                        view={view}
                        setHeaderInfo={setHeaderInfo}
                        sku={sku}
                        region={region}
                        checked={checked}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="status" pt="xs">
                    Status
                </Tabs.Panel>
            </Tabs>
            <Drawer
                opened={opened}
                onClose={() => setOpened(undefined)}
                position="right"
                size="lg"
                transitionProps={{ transition: 'slide-left' }}
            >
                <div className="p-8">
                    <div className="flex flex-row items-center gap-1 text-sm text-[#466CF1]">
                        Promo ID: <div className="mr-2 text-[15px]">{view}</div>
                    </div>
                    <div className="my-3 flex flex-row gap-3 text-sm">
                        MACO: {formatVal(data?.promoSpecData?.incremental_maco_total, 'MACO')}{' '}
                        <div>|</div> Volume:{' '}
                        {formatVal(data?.promoSpecData?.incremental_volume_total, 'Volume')}{' '}
                        <div>|</div> Discount Spend:{' '}
                        {formatVal(data?.promoSpecData?.discount_spend_total, 'MACO')}
                    </div>
                    <StyledTable
                        striped={false}
                        highlightOnHover
                        withTableBorder={false}
                        withColumnBorders={false}
                        minHeight={window.innerHeight * 0.75}
                        maxHeight={window.innerHeight * 0.75}
                        loading={skuLoading}
                        dataCondition={false}
                        body={skuTable}
                        headers={
                            <Table.Tr
                                style={{
                                    background: 'var(--body-bg)',
                                }}
                            >
                                {['SKU', 'Product Name'].map((d, i) => (
                                    <Table.Th key={`header-${d}`} className="!text-sm">
                                        <div
                                            className={classNames(
                                                'flex flex-row items-center gap-2 w-fit rounded-lg',
                                            )}
                                        >
                                            {d}
                                        </div>
                                    </Table.Th>
                                ))}
                            </Table.Tr>
                        }
                    />
                </div>
            </Drawer>
        </div>
    );
};

export default IndividualPromo;
