import { PROMOS_API_URL } from '../../buildConfig/processEnv';

interface PromoSpecDataType {
    incremental_maco_total: number;
    incremental_volume_total: number;
    revenue_volume_total: number;
    optimization: string;
    start_date: string;
    end_date: string;
    discount_range: string;
    quantity_range: string;
    allocation: string;
    strategy: string;
    selected_pid: string;
    apply_lifecycle: boolean;
    n_pocs_offered: number;
    sku_limit: number;
    order_availability: number;
    discount_spend_total: number;
    reporting_sales_region: string;
    coverage: {
        control: number[];
        treatment: number[];
        time: string[];
    };
    discount_spend: {
        control: number[];
        treatment: number[];
        time: string[];
    };
    maco_per_poc: {
        control: number[];
        treatment: number[];
        time: string[];
    };
    volume_per_poc: {
        control: number[];
        time: string[];
    };
    revenue_per_poc: {
        control: number[];
        time: string[];
    };
    skus: Record<'pid' | 'high_pid', string>[];
}
// reporting_sales_region
interface PromoSpecDataFormat {
    promoSpecData: PromoSpecDataType;
    promoSpecError: number | undefined;
}

const PromoSpecData = async (
    token?: string,
    id?: string,
    pid?: string,
    country?: string,
): Promise<PromoSpecDataFormat> => {
    let promoSpecError: number | undefined;
    const url = new URL(`${PROMOS_API_URL}/promos/api/v2/promo-spec-data/`);

    const postReq = async () => {
        const response = await fetch(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                country,
                promo_spec_id: id,
                pid,
            }),
        });

        if (response.status >= 400) {
            promoSpecError = response.status;
            return false;
        }
        const results = JSON.parse((await response.text()).replaceAll('NaN', 'null'));
        return results;
    };

    const promoSpecData = await postReq();
    return {
        promoSpecData,
        promoSpecError,
    };
};

export default PromoSpecData;
export type { PromoSpecDataType };
