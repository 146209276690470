import { Button } from '@mantine/core';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

const TabButton = React.forwardRef(
    (
        {
            leftIcon,
            onClick,
            disabled,
            children,
            isActive,
            id,
            width = 150,
            className,
        }: {
            onClick: () => void;
            leftIcon?: JSX.Element;
            disabled?: boolean;
            children: string;
            className?: string;
            isActive: boolean;
            id: string;
            width?: number | string;
        },
        ref: React.Ref<HTMLButtonElement>,
    ) => (
        <>
            <Button
                id={id}
                ref={ref}
                style={{
                    width,
                    height: '32px',
                }}
                variant="subtle"
                className={classNames(
                    `rounded-lg text-sm text-navy-solid-70 cursor-pointer hover:text-navy-solid-50 font-medium capitalize`,
                    {
                        '!text-navy-solid-70': isActive,
                        '!text-navy-solid-30': disabled,
                        '!cursor-default': disabled || isActive,
                    },
                    className,
                )}
                leftSection={leftIcon}
                classNames={{
                    loader: 'hidden',
                }}
                onClick={onClick}
                disabled={disabled || isActive}
                type="button"
                loading={false}
                unstyled
            >
                {children}
            </Button>
            {isActive ? (
                <motion.div className="h-0.5 w-full rounded bg-risd-blue" layoutId="underline" />
            ) : null}
        </>
    ),
);

export default TabButton;
