import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import { PromosCompareType } from '../../types/components/promos';
import { promosFilterBuilder } from '../../utils/promosHelpers';
import { PROMOS_API_URL } from '../../buildConfig/processEnv';
import { dateHelper } from '../../utils/dateHelpers';

const PromoCompare = (
    token?: string,
    country?: string,
    date?: [[string, string], [string, string]],
    selections?: [NewTypeSelectValues, NewTypeSelectValues],
    ids?: [Record<string, string>, Record<string, string>],
): [UseMutateAsyncFunction<PromosCompareType[], unknown, void, unknown>, unknown, boolean] => {
    let promosCompareError: number | undefined;
    const url = new URL(`${PROMOS_API_URL}/promos/api/v2/performance_comparison/`);

    const postReq = async () => {
        try {
            let response;
            try {
                response = await fetch(`${url}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify({
                        country,
                        ...(selections && {
                            filter:
                                selections.length < 1
                                    ? {}
                                    : [
                                          promosFilterBuilder(dateHelper(date[0]), selections[0]),
                                          promosFilterBuilder(dateHelper(date[1]), selections[1]),
                                      ],
                        }),
                        ...(ids && { spec_sku: ids }),
                    }),
                });
            } catch (err) {
                promosCompareError = err;
                return err;
            }
            const results = JSON.parse((await response.text()).replaceAll('Traceback', 'null'));
            return results;
        } catch (error) {
            promosCompareError = error;
            return error;
        }
    };

    const { mutateAsync, error, isPending } = useMutation({ mutationFn: postReq });
    return [mutateAsync, promosCompareError, isPending];
};

export default PromoCompare;
export type { PromosCompareType };
