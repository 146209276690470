import Promos from '@/components/promos/PromosV2';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { PROMOS_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '../components/utils/UserGuard';

export default function PromosPage() {
    return (
        <ResponsiveLayout title="Promos" className="!pb-0">
            <UserGuard userGroupWhitelist={PROMOS_USER_GROUP_WHITELIST}>
                <Promos />
            </UserGuard>
        </ResponsiveLayout>
    );
}
