import { Indicator, Skeleton } from '@mantine/core';
import classNames from 'classnames';
import { StyledContainer } from '../../types/components/promos';
import { ChartContainer } from '../console/experiments/DMS/ChartContainer';

export const StyledChartContainer = ({
    children,
    title,
    loading,
    action,
    error,
    className,
    chartClassName,
    childrenClassName,
    disableMinHeight,
    errorSubtext,
    errorIcon,
    titleClassName,
    loaderClassName,
}: StyledContainer) =>
    loading ? (
        <Skeleton className={classNames('w-1/2 h-[350px]', loaderClassName)} />
    ) : (
        <ChartContainer
            className={classNames('w-1/2 shadow-md rounded-lg', className, {
                '!h-[38vh]': !disableMinHeight && !className,
            })}
            title={title}
            action={action}
            error={error}
            childrenClassName={childrenClassName}
            disableMinHeight={disableMinHeight}
            errorSubtext={errorSubtext}
            errorIcon={errorIcon}
            titleClassName={titleClassName}
        >
            <div className={classNames({ 'h-[29vh]': !chartClassName }, chartClassName)}>
                {children}
            </div>
        </ChartContainer>
    );
export const Legend = (
    <div className="mt-3 flex flex-row items-center justify-end gap-4">
        <Indicator
            inline
            size={8}
            offset={5}
            position="middle-start"
            color="#F11B97"
            className="pl-3 text-fuchsia"
        >
            Treatment
        </Indicator>
        <Indicator inline size={8} offset={5} position="middle-start" color="gray" className="pl-4">
            Control
        </Indicator>
    </div>
);
