import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import { PROMOS_API_URL } from '../../buildConfig/processEnv';
import { TABLE_SORTING } from '../../constants/siberia/promos';
import { SortType } from '../../types/components/promos';
import { dateHelper } from '../../utils/dateHelpers';
import { promosFilterBuilder } from '../../utils/promosHelpers';

interface AllPromosSpecsType {
    promo_spec_view: {
        promo_spec_id: string;
        pid: string;
        startdate: string;
        duration: number;
        optimization: string;
        strategy: string;
        kpi: string;
        maco_inc: number;
        highpid: string;
        enddate: string;
        discount_range: string;
        quantity_range: string;
        poc_sales_regions: string;
        volume_inc: number;
        coverage_comp: number;
        price_segmentation: string;
        creatorname: string;
        brand: string;
        category: string;
        applylifecyclefiltering: boolean;
        allocator_used: boolean;
        discount_type: string;
    }[];
    num_pages: number;
}
interface AllPromosSpecDataFormat {
    allPromosSpecDataResponse: AllPromosSpecsType;
    allPromosSpecDataError: number | undefined;
}

const AllPromosSpecsView = async (
    token?: string,
    country?: string,
    date?: [string, string],
    selections?: NewTypeSelectValues,
    activePage?: number,
    promoSearch?: string,
    numPerPage = 1000,
    sort?: [string, SortType],
): Promise<AllPromosSpecDataFormat> => {
    let allPromosSpecDataError: number | undefined;
    const url = new URL(`${PROMOS_API_URL}/promos/api/v2/promo-specs-view/`);
    let searchVal = '';
    if (promoSearch !== '') {
        searchVal = `${promoSearch.length >= 8 ? `${promoSearch.slice(0, 8)}-` : ''}${
            promoSearch.length >= 12 ? `${promoSearch.slice(8, 12)}-` : ''
        }${promoSearch.length >= 16 ? `${promoSearch.slice(12, 16)}-` : ''}${
            promoSearch.length >= 20 ? `${promoSearch.slice(16, 20)}-` : ''
        }${promoSearch.length >= 24 ? `${promoSearch.slice(20, 24)}00000000` : ''}`;
    }

    const postReq = async () => {
        const response = await fetch(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                country,
                filter: promosFilterBuilder(dateHelper(date), selections),
                num_per_page: numPerPage,
                page: activePage,
                ...(searchVal !== '' && { promo_spec_id: searchVal }),
                ...(searchVal === '' && promoSearch !== '' && { promo_spec_id: promoSearch }),
                ...(sort &&
                    sort[1] !== 'none' && {
                        order_by: TABLE_SORTING[sort[0]],
                        ascending: sort[1] === 'asc',
                    }),
            }),
        });

        if (response.status >= 400) {
            allPromosSpecDataError = response.status;
            return false;
        }
        const results = JSON.parse(
            (await response.text()).replaceAll('NaN', 'null').replaceAll('Infinity', 'null'),
        );
        return results;
    };

    const allPromosSpecDataResponse = await postReq();
    return {
        allPromosSpecDataResponse,
        allPromosSpecDataError,
    };
};

export default AllPromosSpecsView;
export type { AllPromosSpecsType };
