import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Tooltip } from '@mantine/core';
import LineChart from '../../utils/charts/lineChart';
import { DATA } from '../../../constants/siberia/promos';
import { formatNumber } from '../../../utils/numberFormatHelpers';
import { OverviewProps } from '../../../types/components/promos';
import { Legend, StyledChartContainer } from '../utils';
import { headerHelper } from '../../../utils/promosHelpers';

export const Overview = ({ setHeaderInfo, data, loading, region, checked }: OverviewProps) => {
    useEffect(() => {
        setHeaderInfo({
            macoLoad: loading,
            maco: headerHelper(data?.promoSpecData?.incremental_maco_total, '$0,0'),
            revenue: headerHelper(data?.promoSpecData?.revenue_volume_total, '$0,0'),
            volume: headerHelper(data?.promoSpecData?.incremental_volume_total, '0,0', 'hL'),
            active: headerHelper(data?.promoSpecData?.discount_spend_total, '$0,0'),
        });
    }, [data, loading, setHeaderInfo]);
    const chartData = useMemo(() => {
        const DATA_POINTS = [
            region === 'AR' && checked ? 'revenue_per_poc' : 'maco_per_poc',
            'volume_per_poc',
            'coverage',
            'discount_spend',
        ];
        const formattedData = DATA_POINTS.map(point =>
            Object.keys(DATA).map(label => ({
                label,
                borderWidth: 1,
                borderColor: DATA[label],
                backgroundColor: `${DATA[label]}0D`,
                fill: true,
                data: data?.promoSpecData?.[point]?.[label.toLowerCase()]?.map((row, i) => ({
                    x: moment(data?.promoSpecData?.[point]?.time[i]).format('YYYY-MM-DD'),
                    y: row,
                })),
                average: Number(data?.promoSpecData?.[point]?.summary),
                error: _.sum(data?.promoSpecData?.[point]?.[label.toLowerCase()]) === 0,
            })),
        );
        return { formattedData };
    }, [checked, data?.promoSpecData, region]);

    return (
        <>
            <div className="mt-2 flex flex-row gap-4">
                <StyledChartContainer
                    title={
                        <Tooltip
                            label={`${
                                (region === 'AR' && checked) || region === 'PH'
                                    ? 'Revenue per Door'
                                    : 'MACO per POC'
                            } offered in ${region === 'PH' ? 'PHP' : 'USD'}`}
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                Cumulative{' '}
                                {(region === 'AR' && checked) || region === 'PH'
                                    ? 'Revenue per Door'
                                    : 'MACO per POC'}
                            </div>
                        </Tooltip>
                    }
                    loading={loading}
                    action={
                        <div>
                            {formatNumber(
                                Number(chartData?.formattedData?.[0]?.[0]?.average),
                                '0.00%',
                            )}{' '}
                            <div className="text-sm text-gray-600">UPLIFT</div>
                        </div>
                    }
                    error={
                        chartData?.formattedData?.[0]?.[0]?.data?.length === 1
                            ? 'Promo Lasted a Single Day'
                            : (chartData?.formattedData?.[0]?.[0]?.data?.length < 1 ||
                                  (chartData?.formattedData?.[0]?.[0]?.error &&
                                      chartData?.formattedData?.[0]?.[1]?.error)) &&
                              'Awaiting Data'
                    }
                    className="!h-[34vh]"
                    chartClassName="!h-[26vh]"
                >
                    <LineChart
                        data={{ datasets: chartData?.formattedData?.[0] }}
                        legend={{ display: false }}
                    />
                </StyledChartContainer>
                <StyledChartContainer
                    title={
                        <Tooltip
                            label={`${region === 'PH' ? 'Weight per Door' : 'Volume per POC'} offered in ${region === 'PH' ? 'kg' : 'hL'}`}
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                Cumulative {region === 'PH' ? 'Weight per Door' : 'Volume per POC'}
                            </div>
                        </Tooltip>
                    }
                    loading={loading}
                    action={
                        <div>
                            {formatNumber(
                                Number(chartData?.formattedData?.[1]?.[0]?.average),
                                '0.00%',
                            )}{' '}
                            <div className="text-sm text-gray-600">UPLIFT</div>
                        </div>
                    }
                    error={
                        chartData?.formattedData?.[1]?.[0]?.data?.length === 1
                            ? 'Promo Lasted a Single Day'
                            : (chartData?.formattedData?.[1]?.[0]?.data?.length < 1 ||
                                  (chartData?.formattedData?.[1]?.[0]?.error &&
                                      chartData?.formattedData?.[1]?.[1]?.error)) &&
                              'Awaiting Data'
                    }
                    className="!h-[34vh]"
                    chartClassName="!h-[26vh]"
                >
                    <LineChart
                        data={{ datasets: chartData?.formattedData?.[1] }}
                        legend={{ display: false }}
                        yAxis={{
                            ticks: {
                                maxTicksLimit: 7,
                            },
                        }}
                        tooltip={{
                            callbacks: {
                                label: context => Number(context['raw']['y']).toFixed(4),
                            },
                        }}
                    />
                </StyledChartContainer>
            </div>
            <div className="mt-3 flex flex-row gap-4">
                <StyledChartContainer
                    title={
                        <Tooltip
                            label={`Percent of ${region === 'PH' ? 'Doors' : 'POCs'} offered the promotion that have purchased`}
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                {region === 'PH' ? 'Distribution' : 'Coverage'}
                            </div>
                        </Tooltip>
                    }
                    loading={loading}
                    action={
                        <div>
                            {formatNumber(
                                Number(chartData?.formattedData?.[2]?.[0]?.average),
                                '0.00%',
                            )}{' '}
                            <div className="text-sm text-gray-600">UPLIFT</div>
                        </div>
                    }
                    error={
                        chartData?.formattedData?.[2]?.[0]?.data?.length === 1
                            ? 'Promo Lasted a Single Day'
                            : (chartData?.formattedData?.[2]?.[0]?.data?.length < 1 ||
                                  (chartData?.formattedData?.[2]?.[0]?.error &&
                                      chartData?.formattedData?.[2]?.[1]?.error)) &&
                              'Awaiting Data'
                    }
                    className="!h-[34vh]"
                    chartClassName="!h-[26vh]"
                >
                    <LineChart
                        data={{ datasets: chartData?.formattedData?.[2] }}
                        legend={{ display: false }}
                        yAxis={{
                            ticks: {
                                callback(tickValue) {
                                    return formatNumber(tickValue, '0[.]00%');
                                },
                            },
                        }}
                        tooltip={{
                            callbacks: {
                                label: context =>
                                    formatNumber(Number(context['raw']['y']), '0[.]00%'),
                            },
                        }}
                    />
                </StyledChartContainer>
                <StyledChartContainer
                    title={
                        <Tooltip
                            label={
                                region === 'PH'
                                    ? 'Cumulative budget usage in PHP'
                                    : 'Discount percent given, rolling average'
                            }
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                {region === 'PH' ? 'Budget Usage' : 'Discount Spend'}
                            </div>
                        </Tooltip>
                    }
                    loading={loading}
                    action={
                        <div>
                            <Tooltip
                                label="Discount saved by Treatment POCs compared to Control POCs"
                                position="top-start"
                            >
                                <div>
                                    {formatNumber(
                                        Number(
                                            chartData?.formattedData?.[3]?.[0]?.average.toFixed(5),
                                        ) * -1,
                                        '0.00%',
                                    )}{' '}
                                </div>
                            </Tooltip>

                            <div className="text-sm text-gray-600">
                                {region === 'PH' ? 'BUDGET' : 'DISCOUNT'} SAVED
                            </div>
                        </div>
                    }
                    error={
                        chartData?.formattedData?.[3]?.[0]?.data?.length === 1
                            ? 'Promo Lasted a Single Day'
                            : (chartData?.formattedData?.[3]?.[0]?.data?.length < 1 ||
                                  (chartData?.formattedData?.[3]?.[0]?.error &&
                                      chartData?.formattedData?.[3]?.[1]?.error)) &&
                              'Awaiting Data'
                    }
                    className="!h-[34vh]"
                    chartClassName="!h-[26vh]"
                >
                    <LineChart
                        data={{ datasets: chartData?.formattedData?.[3] }}
                        legend={{ display: false }}
                        yAxis={{
                            ticks: {
                                maxTicksLimit: 7,
                                callback(tickValue) {
                                    return formatNumber(
                                        tickValue,
                                        region === 'PH' ? '0,0[.]0' : '0[.]00%',
                                    );
                                },
                            },
                        }}
                        tooltip={{
                            callbacks: {
                                label: context =>
                                    formatNumber(
                                        Number(context['raw']['y']),
                                        region === 'PH' ? '0,0[.]0' : '0[.]00%',
                                    ),
                            },
                        }}
                    />
                </StyledChartContainer>
            </div>
            {Legend}
        </>
    );
};
