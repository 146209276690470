import { PROMOS_API_URL } from '../../buildConfig/processEnv';

export interface PromosFilterOptionsType {
    creatorname: string[];
    pid: string[];
    poc_sales_regions: string[];
    price_segmentation: string[];
    brand: string[];
    category: string[];
}

export interface PromosFilterOptionsFormat {
    promosFilterOptionsResponse: PromosFilterOptionsType;
    promosFilterOptionsError: number | undefined;
}

const PromosFilterOptions = async (
    token?: string,
    country?: string,
): Promise<PromosFilterOptionsFormat> => {
    let promosFilterOptionsError: number | undefined;
    const url = new URL(`${PROMOS_API_URL}/promos/api/v2/filter-options/`);

    const postReq = async () => {
        const response = await fetch(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                country,
            }),
        });

        if (response.status >= 400) {
            promosFilterOptionsError = response.status;
            return false;
        }

        const results = await response.json();
        return results;
    };

    const promosFilterOptionsResponse = await postReq();
    return {
        promosFilterOptionsResponse,
        promosFilterOptionsError,
    };
};

export default PromosFilterOptions;
