import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import { PROMOS_API_URL } from '../../buildConfig/processEnv';
import { promosFilterBuilder } from '../../utils/promosHelpers';

interface AllPromosSpecsType {
    incremental_maco_total: number;
    incremental_volume_total: number;
    revenue_volume_total: number;
    coverage: DataFormat;
    discount_spend: DataFormat;
    active_promo_specs: number;
    incremental_maco: DataFormat;
    incremental_volume: DataFormat;
    incremental_revenue: DataFormat;
}

interface DataFormat {
    control: number[];
    treatment: number[];
    time: string[];
}

interface AllPromosSpecDataFormat {
    allPromosSpecDataResponse: AllPromosSpecsType;
    allPromosSpecDataError: number | undefined;
}

const AllPromosSpecsData = async (
    token?: string,
    country?: string,
    date?: [string, string],
    selections?: NewTypeSelectValues,
): Promise<AllPromosSpecDataFormat> => {
    let allPromosSpecDataError: number | undefined;
    const url = new URL(`${PROMOS_API_URL}/promos/api/v2/all-promo-specs-data/`);

    const postReq = async () => {
        const response = await fetch(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                country,
                filter: promosFilterBuilder(date, selections),
            }),
        });

        if (response.status >= 400) {
            allPromosSpecDataError = response.status;
            return false;
        }

        const results = JSON.parse((await response.text()).replaceAll('NaN', 'null'));
        return results;
    };

    const allPromosSpecDataResponse = await postReq();
    return {
        allPromosSpecDataResponse,
        allPromosSpecDataError,
    };
};

export default AllPromosSpecsData;
