import React from 'react';
import { MapLegendIconProps, ReturnCircleProps } from '../../types/components/consumers';
import { getRGBasArray } from '../../utils/consumersHelpers';

const ReturnCircle: React.FC<ReturnCircleProps> = ({
    cxNumber,
    cyNumber,
    r,
    color,
    disabled,
    opacity,
}) => (
    <circle
        cx={cxNumber}
        cy={cyNumber}
        r={r}
        fill={disabled === true ? 'none' : `rgba(${color.join(',')})`}
        stroke={`rgba(${color.join(',')})`}
        strokeWidth={disabled === true ? 3 : 0}
        opacity={opacity}
    />
);

export const LegendIcon: React.FC<MapLegendIconProps> = ({
    color,
    disabled,
    className,
    widthHeight,
    stackedDots,
    removeMargins,
}) => (
    <svg
        viewBox="0 0 100 100"
        width={widthHeight || 18}
        height={widthHeight || 18}
        className={`${className} ${!removeMargins && 'mr-2'}`}
    >
        {stackedDots ? (
            <>
                <ReturnCircle
                    cxNumber={50}
                    cyNumber={50}
                    r={30}
                    color={getRGBasArray('#ffffff')}
                    disabled={disabled}
                    opacity={1}
                />
                <ReturnCircle
                    cxNumber={50}
                    cyNumber={50}
                    r={20}
                    color={color}
                    disabled={disabled}
                />
            </>
        ) : (
            <ReturnCircle cxNumber={50} cyNumber={50} r={40} color={color} disabled={disabled} />
        )}
    </svg>
);
