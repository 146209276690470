import React, { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import TabButton from './tabButton';
import TabWrapper, { StyledTabProps, useTabContext } from './tabWrapper';

const Nav = () => {
    const { onChange, values, setSelectedTab, selectedTab } = useTabContext();
    return (
        <nav>
            <ul className="flex gap-[40px]">
                {values.map(item => (
                    <li key={`tabs-nav-${item.value}`}>
                        <TabButton
                            id={item.value}
                            onClick={() => {
                                setSelectedTab(item.value);
                                onChange?.(item.value);
                            }}
                            isActive={selectedTab === item.value}
                            disabled={item?.disabled}
                        >
                            {item?.label ?? item.value}
                        </TabButton>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
const Main = () => {
    const { values, selectedTab } = useTabContext();
    const tabsAsObjects = useMemo(
        () =>
            values.reduce(
                (acc: Record<string, (typeof values)[0]>, item) => ({ ...acc, [item.value]: item }),
                {},
            ),
        [values],
    );
    const selectedTabObject = tabsAsObjects?.[selectedTab];
    return (
        <main>
            <AnimatePresence mode="wait">
                <motion.div
                    key={`selected-component-${selectedTabObject ? selectedTabObject.value : 'empty'}`}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2, delayChildren: 0.2 }}
                >
                    {selectedTabObject ? selectedTabObject.component : '😋'}
                </motion.div>
            </AnimatePresence>
        </main>
    );
};

function StyledTab<Tabs extends string>({
    values = [],
    onChange,
    defaultTab,
    value,
}: StyledTabProps<Tabs>) {
    return (
        <TabWrapper values={values} onChange={onChange} defaultTab={defaultTab} value={value}>
            <Nav />
            <Main />
        </TabWrapper>
    );
}

StyledTab.Nav = Nav;
StyledTab.Main = Main;
StyledTab.Wrapper = TabWrapper;
export default StyledTab;
