/* eslint-disable @typescript-eslint/ban-ts-comment */
import DeckGL from '@deck.gl/react';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { forwardRef } from 'react';
import classNames from 'classnames';
import { Spinner } from '../Spinners';
import { Legend, MapHeatLegend } from './legend';
import { MapWrapperProps } from '../types/mapWrapper';
import useColorScheme from '../../hooks/useColorScheme';
import { MAPBOX_ACCESS_TOKEN } from '../../buildConfig/processEnv';

const lightMapStyle = {
    mapStyle: 'mapbox://styles/mlgardner/clh9imalh01vm01p497p37a4p',
};

const MapWrapper = (
    {
        layers,
        viewPort,
        setViewPort,
        tooltip,
        legend,
        typesToHide,
        setTypesToHide,
        legendColor,
        children,
        loading,
        onViewportChange,
        getCursor,
        onLoad,
        legendTitle,
        legendHeatColors,
        onInteractionStateChange,
        legendClassname,
        projection,
        heatLegendClassName,
        mapboxAccessToken,
        spinnerColor,
    }: MapWrapperProps,
    ref,
) => {
    const { colorScheme } = useColorScheme();
    return (
        <>
            <DeckGL
                ref={ref}
                controller
                layers={Array.isArray(layers) ? layers : [layers]}
                viewState={viewPort}
                onViewStateChange={
                    onViewportChange ||
                    (({ viewState: viewStateInner }) => setViewPort?.(viewStateInner as any))
                }
                getTooltip={({ object }) => tooltip && tooltip(object)}
                onInteractionStateChange={onInteractionStateChange}
            >
                <Map
                    mapStyle={
                        colorScheme === 'light'
                            ? lightMapStyle.mapStyle
                            : `mapbox://styles/mapbox/dark-v10`
                    }
                    mapboxAccessToken={mapboxAccessToken ?? MAPBOX_ACCESS_TOKEN}
                    projection={{ name: 'mercator', ...projection }}
                    onLoad={onLoad}
                />
            </DeckGL>
            {legend && (
                <div
                    className={classNames(
                        'absolute bg-[#272A31] bottom-6 right-6 rounded-md',
                        legendClassname,
                    )}
                >
                    {legend === 'value' ? (
                        <Legend
                            legend={legendTitle}
                            typesToHide={typesToHide}
                            setTypesToHide={setTypesToHide}
                            list={Object.keys(legendColor)}
                            typeColors={legendColor}
                            stackedDots
                        />
                    ) : (
                        <MapHeatLegend
                            legend={legendTitle}
                            colors={legendHeatColors}
                            className={heatLegendClassName}
                        />
                    )}
                </div>
            )}
            {children && children}
            {loading && <Spinner color={spinnerColor} />}
        </>
    );
};
export default forwardRef(MapWrapper);
