import { ArrowRight, CaretDown, CaretUp, Globe, MagnifyingGlass } from 'phosphor-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Drawer, Skeleton, Switch, Table, TextInput, Tooltip } from '@mantine/core';
import classNames from 'classnames';
import { useQueries, useQuery } from '@tanstack/react-query';
import { max, min } from 'lodash';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useDebouncedValue } from '@mantine/hooks';
import ContentGrid from '@/components/responsive/ContentGrid';
import Select from 'andromeda/selects/select';
import StyledTable from 'andromeda/styledTable';
import {
    NewSelectPossibleValues,
    NewTypeSelectValues,
} from 'andromeda/types/select/newTypeOfSelect';
import BarChart from '../utils/charts/barChart';
import AllPromosSpecData from '../../services/siberia/allPromosSpecsView';
import { useAppContext } from '../../contexts/appContext';
import { AllPromoProps, SortType } from '../../types/components/promos';
import {
    ADDED_TABLE_ROWS,
    CATEGORY_DATA,
    CHART_COLORS,
    CHART_COLORS_POS,
    CHART_MAP,
    commonProps,
    currentPercentage,
    ILLEGAL_COLS,
    REGION_SELECTOR,
    REGION_SELECTOR_NESTLE,
    TABLE_SORTING,
} from '../../constants/siberia/promos';
import { Legend } from './utils';
import { formatNumber } from '../../utils/numberFormatHelpers';
import { formatVal, getKeyByValue, granularFilterHelper } from '../../utils/promosHelpers';
import MultiLevelSelect from '../../andromeda/multiSelect/multiLevelSelect';
import DatePicker from '../../andromeda/DatePicker';
import useRouterQuery from '../../hooks/useRouterQuery';
import PromoSKUs from '../../services/siberia/promoSKUs';
import { ChartContainer } from '../console/experiments/DMS/ChartContainer';
import useColorScheme from '../../hooks/useColorScheme';
import { colorsScheme, divergentScales, primaryColors } from '../../constants/colors';
import { useUserAccess } from '../../hooks/useUserAccess';
import Pagination from '../../andromeda/Pagination';
import { CommonProps } from '../responsive/types';

const DeltaLabel = ({ label, children }: Pick<CommonProps, 'children'> & { label: string }) => (
    <div className="mb-4 flex flex-col items-end">
        {children}
        <div className="text-xs text-gray-600">{label}</div>
    </div>
);

const AllPromos = ({
    region,
    setRegion,
    view,
    setView,
    countryData,
    countryLoading,
    setSku,
    date,
    setDate,
    value,
    setValue,
    filterOptions,
    checked,
    setChecked,
    setCount,
}: AllPromoProps) => {
    const router = useRouter();
    const { user } = useAppContext();
    const [promoSearch, setPromoSearch] = useState<string>('');
    const [debouncedSearchValue] = useDebouncedValue(promoSearch, 1000);
    const [activePage, setPage] = useState(1);
    const { handleURLParamsChange } = useRouterQuery();
    const [sort, setSort] = useState<[string, SortType]>(['', 'desc']);
    const [opened, setOpened] = useState(undefined);
    const isArenaStaff = useUserAccess({ userGroupWhitelist: 'arena-staff' });
    const isNestleUser = useUserAccess({ userGroupWhitelist: ['spaceport-PH-NESTLE-access'] });

    const { colorScheme } = useColorScheme();

    const { data, isLoading: tableLoading } = useQuery({
        queryKey: [
            'AllPromosSpecData',
            user?.siberiaToken,
            region,
            date,
            value,
            activePage,
            debouncedSearchValue,
            sort,
        ],
        queryFn: () =>
            AllPromosSpecData(
                user?.siberiaToken,
                region,
                [date[0], region === 'PH' ? '2024-10-32' : date[1]],
                value,
                activePage,
                debouncedSearchValue,
                100,
                sort,
            ),
        enabled: view === 'Table',
    });

    const { data: promoSKUs, isLoading: skuLoading } = useQuery({
        queryKey: ['PromoSKUsTable', user?.siberiaToken, region, opened?.promo_spec_id],
        queryFn: () => PromoSKUs(user?.siberiaToken, region, opened?.promo_spec_id),
        enabled: view === 'Table' && Boolean(opened),
    });

    const highPIDs = useMemo(
        () =>
            data?.allPromosSpecDataResponse?.promo_spec_view?.map(row => ({
                queryKey: [user?.siberiaToken, region, row?.promo_spec_id],
                queryFn: () => PromoSKUs(user?.siberiaToken, region, row?.promo_spec_id),
                staleTime: Infinity,
                enabled: view === 'Table' && region === 'PH',
            })),
        [data?.allPromosSpecDataResponse?.promo_spec_view, region, user?.siberiaToken, view],
    );

    const results = useQueries({
        queries: highPIDs || [],
    });

    const skuTable = useMemo(
        () =>
            promoSKUs?.promoSKUsResponse?.pid_high_pids?.map((row, i) => (
                <Table.Tr
                    key={`row-${String(i)}`}
                    className={classNames('text-left border-collapse')}
                >
                    <Table.Td className="text-left capitalize">{row?.pid}</Table.Td>
                    <Table.Td className="text-left capitalize">{row?.high_pid}</Table.Td>
                </Table.Tr>
            )),
        [promoSKUs],
    );

    const filterColumns: NewTypeSelectValues = useMemo(() => {
        const cols = {};
        Object.entries(value).forEach(([key, val]) => {
            const v: NewSelectPossibleValues = val;
            if (!ILLEGAL_COLS.includes(key) && v.value.length !== 0 && key) {
                if (cols[key]) {
                    cols[key] += `, ${v.value}`;
                } else {
                    cols[key] = v.value;
                }
            }
        });
        return cols;
    }, [value]);
    const firstStickyColumnRef = useRef<HTMLTableCellElement>(null);
    const body = useMemo(
        () =>
            data?.allPromosSpecDataResponse?.promo_spec_view?.map((mapRow, i) => (
                <Table.Tr
                    key={`row-${String(i)}`}
                    className="relative border-collapse cursor-pointer !bg-table-cell-color text-left"
                    onClick={() => {
                        setSku(mapRow?.['pid']);
                        setView(mapRow.promo_spec_id);
                        setCount(mapRow?.['n_high_pids']);
                        router.replace({
                            pathname: router.pathname,
                            query: {
                                ...router.query,
                                sku: mapRow?.['pid'],
                                view: mapRow?.promo_spec_id,
                                count: mapRow?.['n_high_pids'],
                            },
                        });
                    }}
                >
                    <Table.Td
                        className="sticky left-0 bg-table-cell-color text-left capitalize"
                        ref={firstStickyColumnRef}
                    >
                        {mapRow.promo_spec_id.replace(/0+$/, '').replaceAll('-', '')}
                    </Table.Td>
                    {region === 'PH' && (
                        <>
                            <Table.Td className="text-left capitalize">
                                <Tooltip
                                    label={results?.[i]?.data?.promoSKUsResponse?.pid_high_pids?.[
                                        results?.[i]?.data?.promoSKUsResponse?.pid_high_pids
                                            ?.length > 1
                                            ? 1
                                            : 0
                                    ]?.high_pid?.substring(11)}
                                    disabled={!results}
                                >
                                    <div>{mapRow?.['pid']}</div>
                                </Tooltip>
                            </Table.Td>
                            <Table.Td className="text-left capitalize">
                                <Tooltip
                                    label={results?.[
                                        i
                                    ]?.data?.promoSKUsResponse?.pid_high_pids?.[0]?.high_pid?.substring(
                                        11,
                                    )}
                                    disabled={!results}
                                >
                                    <div>{mapRow?.['sku_to_push']}</div>
                                </Tooltip>
                            </Table.Td>
                        </>
                    )}
                    {region !== 'PH' && (
                        <Table.Td
                            className="sticky !border-r-2 border-border-color bg-table-cell-color text-left capitalize"
                            onClick={e => {
                                if (region === 'CO' || region === 'BR') {
                                    return;
                                }
                                e.stopPropagation();
                                setOpened(mapRow);
                            }}
                            style={{
                                left: (firstStickyColumnRef?.current?.clientWidth ?? 1) - 0.5,
                            }}
                            onKeyDown={e => {
                                e.stopPropagation();
                                setOpened(mapRow);
                            }}
                            aria-hidden="true"
                        >
                            <Tooltip
                                label={mapRow?.['highpid']?.replaceAll('_', ' ')}
                                disabled={region === 'AR'}
                            >
                                <div
                                    className={classNames({
                                        'text-[#466CF1] hover:underline': region === 'AR',
                                    })}
                                >
                                    {region === 'AR' ? mapRow?.['n_high_pids'] : mapRow?.['pid']}
                                </div>
                            </Tooltip>
                        </Table.Td>
                    )}
                    {region !== 'PH' && (
                        <Table.Td className="text-left capitalize">
                            {mapRow?.['creatorname']}
                        </Table.Td>
                    )}
                    <Table.Td className="text-left">
                        {moment(mapRow.startdate).format('DD MMM Y')}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">{mapRow.duration}</Table.Td>
                    <Table.Td className="text-left capitalize">
                        {mapRow.strategy.replace('PROMOS_', '').replaceAll('_', ' ')}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">
                        {String(mapRow.optimization).replace('tiered-discount', 'Stepped')}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">
                        {mapRow.optimization === 'tiered-discount'
                            ? 'Stepped'
                            : mapRow?.['discount_range']}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">
                        {mapRow.optimization === 'tiered-discount'
                            ? 'Stepped'
                            : mapRow?.['quantity_range']}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">
                        {formatVal(
                            (region === 'AR' && checked) || region === 'PHP'
                                ? mapRow['revenue_inc']
                                : mapRow.maco_inc,
                            'MACO',
                        ).replace('$', region === 'PH' ? '₱' : '$')}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">
                        {formatVal(mapRow.volume_inc, 'Volume').replace(
                            'hL',
                            region === 'PH' ? 'kg' : 'hL',
                        )}
                    </Table.Td>
                    <Table.Td className="text-left capitalize">
                        {formatVal(mapRow.coverage_comp, 'Coverage')}
                    </Table.Td>
                    {Object.entries(filterColumns).map(row => (
                        <Table.Td key={`extra-row-${row[0]}`} className="text-left capitalize">
                            {String(mapRow[ADDED_TABLE_ROWS[row[0]]])}
                        </Table.Td>
                    ))}
                </Table.Tr>
            )),
        [
            checked,
            data?.allPromosSpecDataResponse?.promo_spec_view,
            filterColumns,
            region,
            results,
            router,
            setCount,
            setSku,
            setView,
        ],
    );

    const chartData: any = useMemo(() => {
        const CHART_DATA_POINTS = [
            (region === 'AR' && checked) || region === 'PH'
                ? 'incremental_revenue'
                : 'incremental_maco',
            'incremental_volume',
            'coverage',
            'discount_spend',
        ];
        return CHART_DATA_POINTS.map(label => {
            if (!label.includes('incremental')) {
                return CHART_MAP(region)['incremental'].map(row => ({
                    ...commonProps(row),
                    uplift: Number(countryData?.allPromosSpecDataResponse?.[label]?.summary),
                    data: countryData?.allPromosSpecDataResponse?.[label]?.time.flatMap(
                        (time, i) => {
                            if (
                                (moment(time).diff(moment()) > 0 ||
                                    moment(time)
                                        .endOf('month')
                                        .diff(
                                            moment().subtract(1, 'years').endOf('month'),
                                            'months',
                                        ) === 0) &&
                                region !== 'PH'
                            ) {
                                return [];
                            }
                            return {
                                x: moment(time).format('MMM YY'),
                                y: countryData?.allPromosSpecDataResponse?.[label]?.[
                                    row.toLowerCase()
                                ][i],
                            };
                        },
                    ),
                }));
            }
            const cumulativeData = countryData?.allPromosSpecDataResponse?.[label]?.inc_num;
            const minMax: [number, number] = [min(cumulativeData), max(cumulativeData)];

            return CHART_MAP(region)['non-inc'].map(row => ({
                ...commonProps(row),
                uplift: Number(countryData?.allPromosSpecDataResponse?.[label]?.summary),
                data: countryData?.allPromosSpecDataResponse?.[label]?.time.flatMap((time, i) => {
                    if (
                        (moment(time).diff(moment()) > 0 ||
                            moment(time)
                                .endOf('month')
                                .diff(moment().subtract(1, 'years').endOf('month'), 'months') <
                                1) &&
                        region !== 'PH'
                    ) {
                        return [];
                    }
                    return {
                        x: moment(time).format('MMM YY'),
                        y: Number(
                            row !== 'Cumulative'
                                ? countryData?.allPromosSpecDataResponse?.[label]?.inc_num[i]
                                : countryData?.allPromosSpecDataResponse?.[label]?.cumulative[i],
                        ).toFixed(2),
                    };
                }),
                ...(row !== 'Cumulative'
                    ? {
                          borderColor:
                              countryData?.allPromosSpecDataResponse?.[label]?.inc_num?.length === 1
                                  ? primaryColors.pink
                                  : countryData?.allPromosSpecDataResponse?.[label]?.inc_num.map(
                                        val =>
                                            (val > 0 ? CHART_COLORS_POS : CHART_COLORS)[
                                                currentPercentage(val, minMax[0], minMax[1])
                                            ] || divergentScales.positiveLight[40],
                                    ),
                          backgroundColor:
                              countryData?.allPromosSpecDataResponse?.[label]?.inc_num?.length === 1
                                  ? primaryColors.pink
                                  : countryData?.allPromosSpecDataResponse?.[label]?.inc_num.map(
                                        val =>
                                            (val > 0 ? CHART_COLORS_POS : CHART_COLORS)[
                                                currentPercentage(val, minMax[0], minMax[1])
                                            ] || divergentScales.positiveLight[40],
                                    ),
                          yAxisID: 'y',
                      }
                    : {
                          pointRadius: 0,
                          type: 'line',
                          yAxisID: 'y1',
                          borderColor: colorsScheme[colorScheme]['primary-color'],
                      }),
            }));
        });
    }, [checked, countryData?.allPromosSpecDataResponse, region, colorScheme]);

    useEffect(() => {
        if (!router.isReady || !filterOptions?.newFilter?.Designer?.data) {
            return;
        }
        const { page } = router.query;
        if (typeof page === 'string') {
            setPage(Number(page));
        }
        const { all_promos_filter: allPromosFilter, count: countSku } = router.query;
        const promosFilter =
            typeof allPromosFilter === 'string' ? [allPromosFilter] : allPromosFilter;
        if (typeof promosFilter === 'object') {
            const filters = {};
            const dataPoints = {};
            [...CATEGORY_DATA, filterOptions?.newFilter].forEach(row => {
                Object.keys(row).forEach(val => {
                    dataPoints[val] = row[val].data;
                });
            });
            promosFilter.forEach(row => {
                const key = getKeyByValue(dataPoints, row);
                if (!key || !row) {
                    return;
                }
                if (!filters[key]) {
                    filters[key] = { type: 'multiSelect', value: [row] };
                } else {
                    filters[key].value.push(row);
                }
            });
            setValue(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady, router.query, filterOptions]);

    const sortingEl = (current: SortType) => {
        if (current === 'none') {
            return undefined;
        }
        return current === 'desc' ? <CaretDown size={12} /> : <CaretUp size={12} />;
    };

    const sortHandler = (name: string, current: SortType) => {
        const SORTING_CYCLE: SortType[] = ['desc', 'asc', 'none'];
        const method = SORTING_CYCLE[(SORTING_CYCLE.indexOf(current) + 1) % 3];
        setSort([name, method]);
    };

    const Filters = useCallback(
        () => (
            <div className="flex justify-end">
                <div className="flex items-center justify-between gap-3">
                    <MultiLevelSelect
                        data={[...(region === 'PH' ? [] : CATEGORY_DATA), filterOptions?.newFilter]}
                        values={value}
                        onChange={v => {
                            setValue(v);
                            const params = Object.values(v)
                                .flatMap(row =>
                                    typeof row.value === 'object' && row.value.length > 0
                                        ? (row.value as any)
                                        : [],
                                )
                                .flat(1);
                            handleURLParamsChange({
                                key: 'all_promos_filter',
                                value: params,
                            });
                        }}
                        loading={filterOptions?.filterLoading}
                        maxWidth={800}
                    />
                    <DatePicker
                        minDate={date[0]}
                        maxDate={date[1]}
                        handleChange={(s, e) => {
                            setDate(s, e);
                        }}
                        id="promos-all-duration"
                        isMonthSelector
                        isVladDesign
                    />
                    {region === 'AR' && (
                        <Switch
                            className="cursor-pointer"
                            size="xl"
                            checked={checked}
                            onChange={event => setChecked(event.currentTarget.checked)}
                            onLabel="Revenue"
                            offLabel="MACO"
                            classNames={{ trackLabel: '!px-2 !pl-3' }}
                        />
                    )}
                    <Select
                        value={region}
                        data={[
                            ...(!isNestleUser || isArenaStaff ? REGION_SELECTOR : []),
                            ...(isNestleUser || isArenaStaff ? REGION_SELECTOR_NESTLE : []),
                        ]}
                        onChange={v => {
                            setRegion(v);
                            setValue({});
                            router.replace({
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    country: v,
                                    all_promos_filter: [],
                                },
                            });
                        }}
                        width={135}
                        leftSection={<Globe size={20} className="mb-0.5 text-primaryBlue" />}
                        cleanDesign
                    />
                </div>
            </div>
        ),
        [
            router,
            isArenaStaff,
            isNestleUser,
            checked,
            date,
            filterOptions?.filterLoading,
            filterOptions?.newFilter,
            region,
            value,
            setChecked,
            setDate,
            setRegion,
            setValue,
            handleURLParamsChange,
        ],
    );

    return view === 'Table' ? (
        <div className="flex flex-col">
            <div className="mb-3 flex flex-row items-center justify-between">
                <TextInput
                    id="outputs-input-search"
                    placeholder="Search for a Promo ID"
                    leftSection={<MagnifyingGlass size={16} />}
                    className="w-[300px]"
                    variant="filled"
                    value={promoSearch}
                    onChange={e => {
                        setPromoSearch(e.target.value);
                    }}
                    classNames={{
                        input: `!border-border-color focus:!border-primary-50 ${
                            colorScheme === 'dark' && 'bg-primary-5'
                        }`,
                    }}
                    styles={{
                        input: {
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderRadius: 6,
                        },
                    }}
                    disabled={tableLoading}
                />
                <Filters />
            </div>
            <StyledTable
                striped={false}
                highlightOnHover
                withTableBorder={false}
                withColumnBorders={false}
                minHeight="calc(100vh - 294px)"
                maxHeight="calc(100vh - 294px)"
                loading={tableLoading}
                dataCondition={false}
                body={body}
                headers={
                    <Table.Tr className="relative !bg-navy-solid-5">
                        {[
                            'Promo ID',
                            ...(region === 'PH' ? ['SKU to Purchase', 'SKU to Push'] : []),
                            ...(region !== 'PH' ? [region === 'AR' ? 'SKU Count' : 'SKU'] : []),
                            ...(region !== 'PH' ? ['Designer Name'] : []),
                            'Start Date',
                            'Duration',
                            region === 'PH' ? 'Objective' : 'Strategy',
                            region === 'PH' ? 'Mechanic' : 'Optimization',
                            region === 'PH' ? 'Discount' : 'Discount Range',
                            'Quantity Range',
                            (region === 'AR' && checked) || region === 'PH' ? 'Revenue' : 'MACO',
                            region === 'PH' ? 'Weight' : 'Volume',
                            region === 'PH' ? 'Distribution' : 'Coverage',
                            ...Object.keys(filterColumns),
                        ].map((d, i) => (
                            <Table.Th
                                key={`header-${d}`}
                                className={classNames('!text-sm !text-navy-solid-70', {
                                    'sticky !bg-navy-solid-5 left-0': i <= 1,
                                    '!border-r-2 border-border-color': i === 1,
                                })}
                                style={{
                                    left:
                                        i === 1 &&
                                        (firstStickyColumnRef?.current?.clientWidth ?? 1) - 0.5,
                                }}
                            >
                                <div
                                    className={classNames(
                                        'flex flex-row items-center gap-2 w-fit rounded-lg',
                                        {
                                            'cursor-pointer hover:bg-shadow-100':
                                                Object.keys(TABLE_SORTING).includes(d),
                                        },
                                    )}
                                    onClick={() =>
                                        Object.keys(TABLE_SORTING).includes(d) &&
                                        sortHandler(d, sort[0] !== d ? 'none' : sort[1])
                                    }
                                    aria-hidden
                                >
                                    {d} {sort[0] === d && sortingEl(sort[1])}
                                </div>
                            </Table.Th>
                        ))}
                    </Table.Tr>
                }
            />
            <Drawer
                opened={opened}
                onClose={() => setOpened(undefined)}
                position="right"
                size="lg"
                transitionProps={{ transition: 'slide-left' }}
            >
                <div className="p-8">
                    <div className="flex flex-row items-center gap-1 text-sm text-[#466CF1]">
                        Promo ID: <div className="mr-2 text-[15px]">{opened?.promo_spec_id}</div>
                        <Button
                            rightSection={<ArrowRight />}
                            loading={false}
                            variant="gradient"
                            className="bg-gray-800 text-[#466CF1]"
                            onClick={() => {
                                setSku(opened?.['pid']);
                                setView(opened?.promo_spec_id);
                                setCount(opened?.['n_high_pids']);
                                router.replace({
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        sku: opened?.['pid'],
                                        view: opened?.promo_spec_id,
                                        count: opened?.['n_high_pids'],
                                    },
                                });
                            }}
                        >
                            Promo Details
                        </Button>
                    </div>
                    <div className="my-3 flex flex-row gap-3 text-sm">
                        MACO: {formatVal(opened?.maco_inc, 'MACO')} <div>|</div> Volume:{' '}
                        {formatVal(opened?.volume_inc, 'Volume')} <div>|</div> Coverage:{' '}
                        {formatVal(opened?.coverage_comp, 'Coverage')}
                    </div>
                    <StyledTable
                        striped={false}
                        highlightOnHover
                        withTableBorder={false}
                        withColumnBorders={false}
                        minHeight="calc(100vh - 294px)"
                        maxHeight="calc(100vh - 294px)"
                        loading={skuLoading}
                        dataCondition={false}
                        body={skuTable}
                        headers={
                            <Table.Tr
                                style={{
                                    background: 'var(--body-bg)',
                                }}
                            >
                                {['SKU', 'Product Name'].map((d, i) => (
                                    <Table.Th key={`header-${d}`} className="!text-sm">
                                        <div
                                            className={classNames(
                                                'flex flex-row items-center gap-2 w-fit rounded-lg',
                                            )}
                                        >
                                            {d}
                                        </div>
                                    </Table.Th>
                                ))}
                            </Table.Tr>
                        }
                    />
                </div>
            </Drawer>
            {!tableLoading ? (
                <Pagination
                    className="self-center pt-9"
                    page={activePage}
                    onChange={v => {
                        setPage(v);
                        router.replace({
                            pathname: router.pathname,
                            query: {
                                ...router.query,
                                page: v,
                            },
                        });
                    }}
                    total={data?.allPromosSpecDataResponse?.num_pages}
                />
            ) : (
                <Skeleton className="h-9 w-72 self-center pt-9" />
            )}
        </div>
    ) : (
        <div className="flex flex-col gap-5">
            <Filters />
            <ContentGrid maxCols={2} className="md:!grid-cols-1 xl:!grid-cols-2">
                <ChartContainer
                    title={
                        <Tooltip
                            label={`Incremental ${
                                (region === 'AR' && checked) || region === 'PH' ? 'Revenue' : 'MACO'
                            } in ${region === 'PH' ? 'PHP' : 'USD'}`}
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                Incremental{' '}
                                {(region === 'AR' && checked) || region === 'PH'
                                    ? 'Revenue'
                                    : 'MACO'}
                            </div>
                        </Tooltip>
                    }
                    loading={countryLoading}
                    action={
                        <DeltaLabel label="UPLIFT">
                            {formatNumber(Number(chartData?.[0]?.[0]?.uplift), '0.00%')}
                        </DeltaLabel>
                    }
                    error={granularFilterHelper(chartData?.[0]) && 'Filters Yielded No Results'}
                    className="!h-[34vh]"
                    childrenClassName="!h-[26vh]"
                    disableMinHeight
                >
                    <BarChart
                        data={{
                            datasets: chartData?.[0],
                        }}
                        legend={{ display: false }}
                        extraYAxis={{
                            y1: {
                                position: 'right',
                            },
                        }}
                        className="pb-5"
                        tooltip={{
                            mode: 'index',
                        }}
                    />
                </ChartContainer>
                <ChartContainer
                    title={
                        <Tooltip
                            label={`Incremental ${region === 'PH' ? 'Weight' : 'Volume'} in ${
                                region === 'PH' ? 'kg' : 'hL'
                            }`}
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                Incremental {region === 'PH' ? 'Weight' : 'Volume'}
                            </div>
                        </Tooltip>
                    }
                    loading={countryLoading}
                    action={
                        <DeltaLabel label="UPLIFT">
                            {formatNumber(Number(chartData?.[1]?.[0]?.uplift), '0.00%')}
                        </DeltaLabel>
                    }
                    error={granularFilterHelper(chartData?.[1]) && 'Filters Yielded No Results'}
                    className="!h-[34vh]"
                    childrenClassName="!h-[26vh]"
                    disableMinHeight
                >
                    <BarChart
                        data={{
                            datasets: chartData?.[1],
                        }}
                        legend={{ display: false }}
                        extraYAxis={{
                            y1: {
                                position: 'right',
                            },
                        }}
                        className="pb-5"
                        tooltip={{
                            mode: 'index',
                        }}
                    />
                </ChartContainer>
                <ChartContainer
                    title={
                        <Tooltip
                            label={`Percent of ${
                                region === 'PH' ? 'Doors' : 'POCs'
                            } offered the promotion that purchased`}
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                {region === 'PH' ? 'Distribution' : 'Coverage'}
                            </div>
                        </Tooltip>
                    }
                    loading={countryLoading}
                    action={
                        <DeltaLabel label="UPLIFT">
                            {formatNumber(Number(chartData?.[2]?.[0]?.uplift), '0.00%')}
                        </DeltaLabel>
                    }
                    error={granularFilterHelper(chartData?.[2]) && 'Filters Yielded No Results'}
                    className="!h-[34vh]"
                    childrenClassName="!h-[26vh]"
                    disableMinHeight
                >
                    <BarChart
                        data={{
                            datasets: chartData?.[2],
                        }}
                        legend={{ display: false }}
                        yAxis={{
                            ticks: {
                                callback(tickValue) {
                                    return formatNumber(tickValue, '0[.]00%');
                                },
                            },
                        }}
                        tooltip={{
                            callbacks: {
                                label: context =>
                                    formatNumber(Number(context['raw']['y']), '0[.]00%'),
                            },
                        }}
                        className="pb-5"
                    />
                </ChartContainer>
                <ChartContainer
                    title={
                        <Tooltip
                            label={
                                region === 'PH'
                                    ? 'Total budget usage for promotions selected by filters'
                                    : 'Discount spend per POC for promotions in the date period'
                            }
                            withArrow
                            position="top-start"
                        >
                            <div className="pl-[22px]">
                                {region === 'PH' ? 'Budget Usage' : 'Discount Spend'}{' '}
                            </div>
                        </Tooltip>
                    }
                    loading={countryLoading}
                    action={
                        <DeltaLabel label={`${region === 'PH' ? 'BUDGET' : 'DISCOUNT'} SAVED`}>
                            <Tooltip
                                position="top-start"
                                label={
                                    region === 'PH'
                                        ? 'Budget saved by Treatment Doors compared to Control Doors'
                                        : 'Discount saved by Treatment POCs compared to Control POCs'
                                }
                            >
                                <div>
                                    {formatNumber(
                                        Number(chartData?.[3]?.[0]?.uplift) * -1,
                                        '0.00%',
                                    )}
                                </div>
                            </Tooltip>
                        </DeltaLabel>
                    }
                    error={granularFilterHelper(chartData?.[3]) && 'Filters Yielded No Results'}
                    className="!h-[34vh]"
                    childrenClassName="!h-[26vh]"
                    disableMinHeight
                >
                    <BarChart
                        data={{
                            datasets: chartData?.[3],
                        }}
                        legend={{ display: false }}
                        yAxis={{
                            ticks: {
                                callback(tickValue) {
                                    return formatNumber(tickValue, '$0,0.00').replace(
                                        '$',
                                        region === 'PH' ? '₱' : '$',
                                    );
                                },
                            },
                        }}
                        tooltip={{
                            callbacks: {
                                label: context =>
                                    `${formatNumber(Number(context['raw']['y']), '$0,0.00').replace(
                                        '$',
                                        region === 'PH' ? '₱' : '$',
                                    )} ${region !== 'PH' ? 'Spend per POC' : ''}`,
                            },
                        }}
                        className="pb-5"
                    />
                </ChartContainer>
            </ContentGrid>
            {Legend}
        </div>
    );
};

export default AllPromos;
