import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Skeleton, TextInput } from '@mantine/core';
import { MagnifyingGlass } from 'phosphor-react';
import Select from 'andromeda/selects/select';
import MapWrapper from 'andromeda/mapWrapper/mapWrapper';
import LineChart from '../../utils/charts/lineChart';
import { DATA, POC_DATA } from '../../../constants/siberia/promos';
import PromoSpecData from '../../../services/siberia/promoSpecData';
import { useAppContext } from '../../../contexts/appContext';
import { OverviewProps } from '../../../types/components/promos';
import { Legend, StyledChartContainer } from '../utils';
import { INITIAL_VIEW_STATE_PROMOS } from '../../../utils/mapbox';
import { headerHelper } from '../../../utils/promosHelpers';

export const POC = ({ setHeaderInfo, view }: OverviewProps) => {
    const { user } = useAppContext();
    const [promoSearch, setPromoSearch] = useState<string>('');
    const [viewPort, setViewPort] = useState(INITIAL_VIEW_STATE_PROMOS);
    const [selectedSKU, setSelectedSKU] = useState(undefined);
    const [selectedPID, setSelectedPID] = useState(undefined);

    const { data, isLoading: loading } = useQuery({
        queryKey: ['PromoSpecData', user?.siberiaToken, view, selectedSKU],
        queryFn: () => PromoSpecData(user?.siberiaToken, view, selectedSKU),
        enabled: user?.siberiaToken !== null && view !== 'All' && view !== 'Table',
    });

    const chartData = useMemo(() => {
        setHeaderInfo({
            macoLoad: loading,
            maco: headerHelper(data?.promoSpecData?.incremental_maco_total, '$0,0'),
            volume: headerHelper(data?.promoSpecData?.incremental_volume_total, '0,0', 'hL'),
            active: headerHelper(data?.promoSpecData?.discount_spend_total, '$0,0'),
            revenue: headerHelper(data?.promoSpecData?.revenue_volume_total, '$0,0'),
        });
        const skus = data?.promoSpecData?.skus?.map(row => row.high_pid)?.sort();
        const formattedData = ['maco_per_poc'].map(point =>
            Object.keys(DATA).map(label => ({
                label,
                borderWidth: 1,
                borderColor: DATA[label],
                backgroundColor: `${DATA[label]}0D`,
                fill: true,
                data: data?.promoSpecData?.[point]?.[label.toLowerCase()]?.map((row, i) => ({
                    x: moment(data?.promoSpecData?.[point]?.time[i]).format('MM-DD-YY'),
                    y: row,
                })),
                average: _.mean(data?.promoSpecData?.[point]?.[label.toLowerCase()]),
            })),
        );
        const defaultSku = data?.promoSpecData?.skus?.find(
            row => row.pid === data?.promoSpecData?.selected_pid,
        );
        return { formattedData, skus, defaultSku };
    }, [data?.promoSpecData, loading, setHeaderInfo]);

    const skuSelection = (high_pid: string) => {
        setSelectedPID(high_pid);
        const currentSku = data?.promoSpecData?.skus?.filter(row => row.high_pid === high_pid)[0];
        setSelectedSKU(currentSku?.pid);
    };

    return (
        <>
            <div className="pointer-events-none mt-6 flex flex-row items-center justify-between px-6 blur-sm">
                <TextInput
                    id="outputs-input-search"
                    placeholder="Search for a Promo ID"
                    leftSection={<MagnifyingGlass size={16} />}
                    className="w-[300px]"
                    variant="filled"
                    value={promoSearch}
                    onChange={e => {
                        setPromoSearch(e.target.value);
                    }}
                    styles={{
                        input: {
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderRadius: 6,
                        },
                    }}
                    classNames={{
                        input: 'border-border-color focus:border-primary-50 bg-primary-5',
                    }}
                    disabled={loading}
                />
                {loading ? (
                    <Skeleton className="h-12 w-[450px]" />
                ) : (
                    <Select
                        value={selectedPID || chartData?.defaultSku?.high_pid}
                        data={chartData?.skus || []}
                        onChange={skuSelection}
                        className="w-[450px]"
                    />
                )}
            </div>
            <div className="absolute bottom-1/2 left-1/2 z-50">Coming Soon</div>
            <div className="pointer-events-none mt-4 flex flex-row px-6 blur-sm">
                <div className="flex h-[75vh] w-2/5 flex-col items-center overflow-y-auto rounded-md bg-shadow-100 p-4">
                    <div className="">
                        <h1 className="!self-start">The Corner</h1>
                        <div className="!self-start text-sm text-gray-500">
                            República Árabe Siria 2990, C1425EYJ C1425EYJ, Buenos Aires, Argentina
                        </div>
                        <div className="my-6 flex !w-[30vw] flex-col rounded-md border-1 border-border-color bg-shadow-100 p-4">
                            <h1>POC Details</h1>
                            {POC_DATA.map(row => (
                                <div
                                    key={row.col}
                                    className="flex flex-row justify-between px-8 py-1 text-gray-400"
                                >
                                    <div>{row.col}</div> {row.val}
                                </div>
                            ))}
                        </div>
                    </div>
                    <StyledChartContainer
                        className="!w-[30vw]"
                        title="Purchase History"
                        loading={loading}
                        error={
                            chartData?.formattedData?.[0]?.[0]?.data?.length < 1 &&
                            'No data available'
                        }
                    >
                        <LineChart
                            data={{ datasets: chartData?.formattedData?.[0] }}
                            legend={{ display: false }}
                        />
                    </StyledChartContainer>
                </div>

                <div className="absolute ml-[36.5%] h-[75vh] w-[54.2vw]">
                    <MapWrapper
                        layers={[]}
                        viewPort={viewPort}
                        setViewPort={setViewPort}
                        loading={loading}
                    >
                        <div className="absolute bottom-6 right-6 rounded-md bg-shadow-400 px-3 py-1 shadow-md backdrop-blur-md">
                            {Legend}
                        </div>
                    </MapWrapper>
                </div>
            </div>
        </>
    );
};
