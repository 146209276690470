import { NewTypeSelectData } from 'andromeda/types/select/newTypeOfSelect';

export const COLOR_MAP = {
    Treatment: '#F11B97',
    Control: '#81859B',
    Cumulative: 'white',
};
export const COLOR_MAP_CUMULATIVE = ['#C5CAFE', '#3253E1', '#7188FD'];
export const COLOR_MAP_COMPARE = ['#3253E1', '#F11B97'];
export const commonProps = (region: string) => ({
    label: region,
    borderWidth: 1,
    borderColor: COLOR_MAP[region] || '#3054d6',
    backgroundColor: COLOR_MAP[region] || '#3054d6',
    opacity: 0.5,
});
export const CATEGORY_LABELS = {
    creatorname: 'Designer',
    pid: 'SKU',
    reporting_sales_region: 'Region',
    brand: 'Brand',
    category: 'Category',
    price_segmentation: 'Product Type',
};
export const CATEGORY_LABELS_PH = {
    pid: 'SKU',
    brand: 'Brand',
    category: 'Category',
};
export const CATEGORY_DATA: NewTypeSelectData[] = [
    {
        Strategy: {
            type: 'multiSelect',
            data: ['Introduce', 'Sustain', 'Generate Demand', 'Operational', 'Market Share'],
        },
        Optimization: { type: 'multiSelect', data: ['Discount', 'Min. Quantity', 'Stepped'] },
    },
];

export const CHART_MAP = (region: string) => ({
    incremental: ['Treatment', 'Control'],
    'non-inc': ['Cumulative', region],
});

export const DATE_OPTIONS = [
    'Current Month',
    'Last 2 months',
    'Last 3 months',
    'Last 6 months',
    'Last 12 months',
];

export const REGION_SELECTOR = [
    { value: 'BR', label: 'Brazil' },
    { value: 'AR', label: 'Argentina' },
    { value: 'CO', label: 'Colombia' },
];
export const REGION_SELECTOR_NESTLE = [{ value: 'PH', label: 'Philippines' }];
export const KPI_TOOLTIP = {
    MACO: 'Incremental MACO',
    Volume: 'Incremental Volume',
    Coverage: 'Coverage Uplift',
};
export const KPI_COLS = { MACO: 'maco_inc', Volume: 'volume_inc', Coverage: 'coverage_comp' };
export const OPTIONS = [
    { title: 'STRATEGY', value: 'strategy' },
    { title: 'OPTIMIZATION', value: 'optimization' },
    { title: 'DISC. RANGE', value: 'discount_range' },
    { title: 'QUANT. RANGE', value: 'quantity_range' },
    { title: '# OF POCS', value: 'n_pocs_offered' },
    { title: 'ALLOCATION', value: 'allocation' },
    { title: 'LIFECYCLE OPT.', value: 'apply_lifecycle' },
    { title: 'SKU LIMIT', value: 'sku_limit' },
    { title: 'ORDER AVAIL.', value: 'order_availability' },
    { title: 'Region', value: 'reporting_sales_region' },
    { title: 'START DATE', value: 'start_date' },
    { title: 'END DATE', value: 'end_date' },
];

// to be refactored
export const PH_OPTIONS = [
    { title: 'OBJECTIVE', value: 'strategy' },
    { title: 'MECHANIC', value: 'optimization' },
    { title: 'EFFECTIVE DISCOUNT', value: 'discount_range' },
    { title: 'QUANT. RANGE', value: 'quantity_range' },
    { title: '# of Doors', value: 'n_pocs_offered' },
    { title: 'BUDGET CAP', value: 'allocation' },
    { title: 'DOOR SELECTION', value: 'apply_lifecycle' },
    { title: 'START DATE', value: 'start_date' },
    { title: 'END DATE', value: 'end_date' },
];

export const COMPARE_OPTIONS = [
    { title: 'SKU', value: 'sku' },
    { title: 'DISC. RANGE', value: 'discount_range' },
    { title: 'QUANT. RANGE', value: 'quantity_range' },
    { title: 'ALLOCATION', value: 'allocation' },
    { title: 'LIFECYCLE OPT.', value: 'apply_lifecycle' },
    { title: 'STRATEGY', value: 'strategy' },
    { title: 'SKU LIMIT', value: 'sku_limit' },
    { title: 'ORDER AVAIL.', value: 'order_availability' },
];

export const CHART_COLORS = ['#993338', '#D14E5F', '#F48391'];
export const CHART_COLORS_POS = ['#C5CAFE', '#8DA0FD', '#5B75E7', '#3350B6'];

export const currentPercentage = (curr: number, min: number, max: number) =>
    Math.floor(
        ((((Math.abs(curr) - min) / (max - min)) * 100) / (curr > 0 ? 26 : 33.4)) %
            (curr > 0 ? 4 : 3),
    );

export const DATA = { Treatment: '#F11B97', Control: '#808080' };

export const CHART_DATA_COMPARE = [
    {
        label: '63a9f98e47e79e3e68efdfbb',
        backgroundColor: '#3054d6',
        barPercentage: 1,
        categoryPercentage: 0.3,
        data: [
            {
                y: '0.4',
                x: 'MACO UPLIFT',
            },
            {
                y: '0.7',
                x: 'VOLUME UPLIFT',
            },
            {
                y: '0.6',
                x: 'COVERAGE',
            },
        ],
    },
    {
        label: '644fee5a4ae6314358641605',
        backgroundColor: '#a94ca3',
        barPercentage: 1,
        categoryPercentage: 0.3,
        data: [
            {
                y: '0.3',
                x: 'MACO UPLIFT',
            },
            {
                y: '0.15',
                x: 'VOLUME UPLIFT',
            },
            {
                y: '0.5',
                x: 'COVERAGE',
            },
        ],
    },
];

export const COMPARE_TABLE = [
    {
        promo_spec_id: '63a9f98e47e79e3e68efdfbb',
        sku: '988',
        product_name: 'ANTARCTICA_ANTARCTICA PILSEN_300 ML_2X12_GARRAFA',
        optimization: 'Discount',
        start_date: '01 April 2023',
        end_date: '30 April 2023',
        pocs: '7,557',
        vol: '0.08hL',
        spend: '$0.98',
    },
    {
        promo_spec_id: '644fee5a4ae6314358641605',
        sku: '986',
        product_name: 'ANTARCTICA_ANTARCTICA PILSEN_300 ML_2X12_GARRAFA',
        optimization: 'Tiered Discount',
        start_date: '01 May 2023',
        end_date: '31 May 2023',
        pocs: '11,439',
        vol: '0.09hL',
        spend: '$0.99',
    },
];

export const POC_DATA = [
    { col: 'PDV_UNB:', val: '27502_772003' },
    { col: 'POC code:', val: '6654_0845' },
    { col: 'On/off prem:', val: 'On Prem' },
    { col: 'Channel type:', val: 'Bar/Barzinho' },
    { col: 'Segment:', val: 'Bar G' },
    { col: 'POC size:', val: 'Bar Grande Economic' },
    { col: '# SKUs in Promo:', val: '4' },
    { col: 'Avg. Order Amt.:', val: '$347.00' },
    { col: 'Total Order Amt.:', val: '$357' },
    { col: 'Date of Last Order:', val: 'Monday June 12, 2023' },
];

export const ADDED_TABLE_ROWS = {
    Brand: 'brand',
    'Apply Lifecycle': 'applylifecyclefiltering',
    Allocator: 'allocator_used',
    Category: 'category',
    'Product Type': 'price_segmentation',
    Region: 'reporting_sales_region',
};
export const ILLEGAL_COLS = ['Strategy', 'Optimization', 'Designer', 'SKU'];
export const TABLE_SORTING = {
    'Promo ID': 'promo_spec_id',
    SKU: 'pid',
    'Designer Name': 'creatorname',
    'Start Date': 'startdate',
    Duration: 'duration',
    Strategy: 'strategy',
    Optimization: 'optimization',
    'Discount Range': 'discount_range',
    'Quantity Range': 'quantity_range',
    MACO: 'maco_inc',
    Volume: 'volume_inc',
    Coverage: 'coverage_comp',
};
