import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import { formatNumber } from './numberFormatHelpers';

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key].includes(value));
}

export const detailHelper = (str: string, cat: string, opt: string) => {
    if (String(opt).includes('tiered') && (cat === 'DISC. RANGE' || cat === 'QUANT. RANGE'))
        return 'Stepped';
    if (
        (cat === 'DISC. RANGE' || cat === 'QUANT. RANGE' || cat === 'ALLOCATION') &&
        str !== 'UNDEFINED'
    ) {
        const format = `${str
            .split('-')
            .map(row => Number(row.replace('%', '')).toFixed(0))
            .join('-')}${str.includes('%') ? '%' : ''}`;
        if (
            cat === 'DISC. RANGE' &&
            format.split('-')[0] === String(format.split('-')[1]).replace('%', '')
        ) {
            return format.split('-')[1];
        }
        return format;
    }
    return str.replace('TIERED-DISCOUNT', 'Stepped');
};

export const lifecycleFunc = (bool: boolean) =>
    bool ? 'Lifecycle Applied' : 'Lifecycle Not Applied';
export const formatVal = (val, col) => {
    if (col === 'MACO') return formatNumber(val, '$0,0');
    if (col === 'Coverage') return formatNumber(val, '0[.]00%');
    return `${Number(val).toFixed(3)}hL`;
};

export const granularFilterHelper = (data: Record<string, string>[]) => {
    let result = false;
    data.forEach(row => {
        if (row?.data === undefined) result = true;
    });
    return result;
};
export const promosFilterBuilder = (date: [string, string], selections: NewTypeSelectValues) => {
    const filters = {
        search_start_date: date[0],
        search_end_date: date[1],
    };

    Object.entries(selections).forEach(rows => {
        const arr: any[] = typeof rows[1].value === 'string' ? [rows[1].value] : rows[1].value;
        if (arr.length === 0) return;
        const key = rows[0].toLowerCase();
        if (key === 'apply lifecycle' && typeof arr === 'object') {
            filters['apply_lifecycle'] = arr[0] === 'Lifecycle Applied';
        } else if (key === 'allocator' && typeof arr === 'object') {
            filters['full_allocation'] = arr[0] === 'Allocator Used';
        } else if (key === 'sku' && typeof arr === 'object') {
            filters['pid'] = arr;
        } else if (key === 'product type' && typeof arr === 'object') {
            filters['product_type'] = arr;
        } else if (typeof arr === 'object') {
            if (arr.includes('Discount')) {
                filters[key] = [...arr, 'Joint'];
            } else filters[key] = arr.map(row => (row === 'Stepped' ? 'Stepped Quantity' : row));
        } else {
            filters[key] = arr;
        }
    });

    return filters;
};

export const headerHelper = (val: number, format?: '$0,0' | '0,0', adds?: string) => {
    const isInvalid = val === 0 || !val;
    if (isInvalid) return 'N/A';
    return String(format ? formatNumber(val, format) : val) + (adds || '');
};
