import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mantine/core';
import Select from 'andromeda/selects/select';
import LineChart from '../../utils/charts/lineChart';
import PromoSpecData from '../../../services/siberia/promoSpecData';
import { formatNumber } from '../../../utils/numberFormatHelpers';
import { useAppContext } from '../../../contexts/appContext';
import { OverviewProps } from '../../../types/components/promos';
import { StyledChartContainer } from '../utils';
import { INITIAL_VIEW_STATE_CONSUMER } from '../../../utils/mapbox';
import { headerHelper } from '../../../utils/promosHelpers';

export const EngagementFunnel = ({ setHeaderInfo, view }: OverviewProps) => {
    const { user } = useAppContext();
    const [viewPort, setViewPort] = useState(INITIAL_VIEW_STATE_CONSUMER);
    const [selectedSKU, setSelectedSKU] = useState(undefined);
    const [selectedPID, setSelectedPID] = useState(undefined);

    const { data, isLoading: loading } = useQuery({
        queryKey: ['PromoSpecData', user?.siberiaToken, view, selectedSKU],
        queryFn: () => PromoSpecData(user?.siberiaToken, view, selectedSKU),
        enabled: user?.siberiaToken !== null && view !== 'All' && view !== 'Table',
    });

    const chartData = useMemo(() => {
        setHeaderInfo({
            macoLoad: loading,
            maco: headerHelper(data?.promoSpecData?.incremental_maco_total, '$0,0'),
            volume: headerHelper(data?.promoSpecData?.incremental_volume_total, '0,0', 'hL'),
            active: headerHelper(data?.promoSpecData?.discount_spend_total, '$0,0'),
            revenue: headerHelper(data?.promoSpecData?.revenue_volume_total, '$0,0'),
        });
        const skus = data?.promoSpecData?.['skus']?.map(row => row.high_pid)?.sort();
        const formattedData = [
            {
                label: 'SKU',
                borderWidth: 1,
                borderColor: `#F11B97`,
                fill: true,
                data: ['POC Conversion', 'Promos In Bees', 'Viewed', 'Clicked', 'Purchased'].map(
                    (row, i) => ({
                        x: row,
                        y: 1 - Number(`0.${[i]}`),
                    }),
                ),
                segment: {
                    backgroundColor: ctx => {
                        const yVal = ctx.p1.parsed.y;
                        if (yVal <= 0.6) return '#F11B970D';
                        if (yVal <= 0.7) return '#F11B971A';
                        if (yVal <= 0.8) return '#F11B9733';
                        if (yVal <= 0.9) return '#F11B974D';
                        return '#F11B974D';
                    },
                },
            },
        ];
        const defaultSku = data?.promoSpecData?.['skus']?.find(
            row => row.pid === data?.promoSpecData?.['selected_pid'],
        );
        return { formattedData, skus, defaultSku };
    }, [data?.promoSpecData, loading, setHeaderInfo]);

    const skuSelection = (high_pid: string) => {
        setSelectedPID(high_pid);
        const currentSku = data?.promoSpecData?.['skus']?.filter(
            row => row.high_pid === high_pid,
        )[0];
        setSelectedSKU(currentSku?.pid);
    };

    return (
        <>
            <div className="absolute bottom-1/2 left-1/2 z-50 !blur-0">Coming Soon</div>

            <div className="pointer-events-none flex flex-col items-center gap-6 blur-sm">
                <div className="flex flex-row items-center self-end">
                    {loading ? (
                        <Skeleton className="h-12 w-[450px]" />
                    ) : (
                        <Select
                            value={selectedPID || chartData?.defaultSku?.high_pid}
                            data={chartData?.skus || []}
                            onChange={skuSelection}
                            className="w-[450px]"
                        />
                    )}
                </div>
                <div className="flex flex-row items-center">
                    <div>
                        <StyledChartContainer
                            className="!h-[70vh] !w-[70vw] items-center"
                            chartClassName="!h-[60vh]"
                            title="POC Conversion"
                            loading={false}
                        >
                            <LineChart
                                data={{ datasets: chartData?.formattedData }}
                                legend={{ display: false }}
                                tooltip={{
                                    callbacks: {
                                        label: (context: any) =>
                                            formatNumber(context.raw.y, '0.0%'),
                                    },
                                }}
                                yAxis={{
                                    ticks: {
                                        callback(tickValue) {
                                            return formatNumber(tickValue, '0.0%');
                                        },
                                    },
                                }}
                            />
                        </StyledChartContainer>
                    </div>
                </div>
            </div>
        </>
    );
};
